import { useEffect } from "react";
import { useState } from "react";
import { addItemToCart, retriveFrequentlyOrderedItems, retriveHomePageItems, retrivePromotedItems } from "../../services/ItemService";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";
import Slider from "./Slider";
import ShopByCategory from "./ShopByCategory";
import PromotedItems from "./PromotedItems";
import FrequentlyOrderedItems from "./FrequentlyOrderedItems";

const Home = () =>{
   const [topProducts,setTopProducts] = useState([]);
   const [promotedItems,setPromotedItems] = useState([]);
   const [frequentlyOrderedItems,setFrequentlyOrderedItems] = useState([]);
   const [products,setProducts] = useState([]);
   const [wishlistItems,setwishlistItems] = useState([]);
   const { setCartQty, cart_qty, wishlist_qty, setWishlistQty, is_authenticated } = useContext(AppContext);



   useEffect(()=>{
      window.scrollTo(0, 0);
      getItems();
      getWishlist();
   },[]);

   useEffect(() => {
      if(is_authenticated == 1){
         getPromotedItems();
         getFrequentlyOrderedItems();
      }else{
         setPromotedItems([]);
         setFrequentlyOrderedItems([]);
      }
   },[is_authenticated]);
   
   const getItems = async() => {
      await retriveHomePageItems().then((response)=>{
         if(response.data.data){
            let data = response.data.data;   
            setTopProducts(data.filter((cat,index) => index <= 5));
            setProducts(data.filter((cat,index) => index >5));
         }
     }).catch((error) =>{
         console.log("Error", error)
     });
   }
   
   const getPromotedItems = async() => {
      await retrivePromotedItems().then((response)=>{
         if(response.data.data){
            setPromotedItems(response.data.data);
         }
     }).catch((error) =>{
         console.log("Error", error)
     });
   }
   
   const getFrequentlyOrderedItems = async() => {
      await retriveFrequentlyOrderedItems().then((response)=>{
         if(response.data.data){
            setFrequentlyOrderedItems(response.data.data);
         }
     }).catch((error) =>{
         console.log("Error", error)
     });
   }

   const getWishlist = async () => {
      let items = await localStorage.getItem('wishlist_items')
      
      if(items != null){
            items = JSON.parse(items);
            let wishlist_item_ids = items.map((_item) => {return _item.id})
            setwishlistItems(wishlist_item_ids);
      }
   }

   const toggleWishlist = async(item,e) =>{
      e.preventDefault();
      let items = await localStorage.getItem('wishlist_items')
      let set = 0;
      let date = new  Date();
      date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDay() + ", " +  date.getFullYear();

      if(items != null){
         items = JSON.parse(items);
         let ids = items.map(item => item['id']);

         if(ids.includes(item.id)){
            let wishlist_items = items.filter((_item) => _item.id != item.id)
            let wishlist_item_ids = wishlist_items.map((_item) => {return _item.id})
            set = -1;
            setwishlistItems(wishlist_item_ids);
            localStorage.setItem('wishlist_items',JSON.stringify(wishlist_items)); 
            toast("Item Removed from Wishlist",{type:'success'});
         }else{
            item['wishlist_added_date'] = date;
            items.push(item)
            set = 1;
            setwishlistItems([...wishlistItems,item.id])
            localStorage.setItem('wishlist_items',JSON.stringify(items)); 
            toast("Item added into Wishlist",{type:'success'});
         }
      }else{
         let temp = [];
         item['wishlist_added_date'] = date;
         temp.push(item)
         set = 1;
         setwishlistItems([item.id])
         localStorage.setItem('wishlist_items',JSON.stringify(temp));

         toast("Item added into Wishlist",{type:'success'});
      }
      setWishlistQty(wishlist_qty + set);
   }

   const handleAddToCart = (item, e) => {
      e.preventDefault();
      if(isLogin()){
         let item_id = item.id;
         addItemToCart(item_id)
            .then((response) => {
               setCartQty(parseInt(cart_qty) + 1);
               toast('Item Added To Cart Successfully.',{type: 'success'})
            })
            .catch((error) => {
               console.log("Error", error);
               if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
      }else{
         toast('Please login to add item to cart',{type:'error'});
      }
   }
   
    return (
      <main className="main">
      <Slider />
      {/*End hero slider*/}
      <Banner />
      {/*End banners*/}
      <section className="product-tabs section-padding position-relative">
        <div className="container">
          <div className="section-title style-2">
            <h3>Selling Products</h3>
            <ul className="nav nav-tabs links" style={{display:'none'}} id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="nav-tab-one"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-one"
                  type="button"
                  role="tab"
                  aria-controls="tab-one"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-two"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-two"
                  type="button"
                  role="tab"
                  aria-controls="tab-two"
                  aria-selected="false"
                >
                  Milks &amp; Dairies
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-three"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-three"
                  type="button"
                  role="tab"
                  aria-controls="tab-three"
                  aria-selected="false"
                >
                  Coffes &amp; Teas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-four"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-four"
                  type="button"
                  role="tab"
                  aria-controls="tab-four"
                  aria-selected="false"
                >
                  Pet Foods
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-five"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-five"
                  type="button"
                  role="tab"
                  aria-controls="tab-five"
                  aria-selected="false"
                >
                  Meats
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-six"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-six"
                  type="button"
                  role="tab"
                  aria-controls="tab-six"
                  aria-selected="false"
                >
                  Vegetables
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-seven"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-seven"
                  type="button"
                  role="tab"
                  aria-controls="tab-seven"
                  aria-selected="false"
                >
                  Fruits
                </button>
              </li>
            </ul>
          </div>
          {/*End nav-tabs*/}
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="tab-one"
              role="tabpanel"
              aria-labelledby="tab-one"
            >
              <div className="row product-grid-4">
               {
                  products.map((item, index) => {
                     return (
                        <div key={index} className="col-lg-1-5 col-md-4 col-12 col-sm-6 inner-product-grid">
                  <div className="product-cart-wrap mb-30">
                    <div className="product-img-action-wrap">
                      <div className="product-img product-img-zoom">
                        <Link to={"/product/" + item.id + "/" + item.item_name}>
                          <img
                            className="default-img"
                            src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                            alt=""
                          />
                          {/* <img
                            className="hover-img"
                            src="/images/shop/product-1-2.jpg"
                            alt=""
                          /> */}
                        </Link>
                      </div>
                      <div className="product-action-1">
                        <a
                          aria-label="Add To Wishlist"
                          className="action-btn"
                          href=""
                          onClick={(e) => { toggleWishlist(item,e)}}
                        >
                          {
                            wishlistItems.includes(item.id) ?
                            <i className="wisilist-fill">&#10084;</i>
                            :
                            <i className="fi-rs-heart" /> 
                          }
                        </a>
                        {/* <a
                          aria-label="Compare"
                          className="action-btn"
                          href="shop-compare.html"
                        >
                          <i className="fi-rs-shuffle" />
                        </a>
                        <a
                          aria-label="Quick view"
                          className="action-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#quickViewModal"
                        >
                          <i className="fi-rs-eye" />
                        </a> */}
                      </div>
                      {/* <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">Hot</span>
                      </div> */}
                    </div>
                    <div className="product-content-wrap">
                      <div className="product-category">
                        <Link to={"/product-category/" + item.category?.slug}>{item.category?.category_name}</Link>
                      </div>
                      <h2>
                        <Link to={"/product/" + item.id + "/" + item.item_name}>
                          {item.item_name}
                        </Link>
                      </h2>
                      <div className="product-rate-cover">
                        <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style={{ width: "90%" }}
                          />
                        </div>
                        <span className="font-small ml-5 text-muted"> (4.0)</span>
                      </div>
                      <div>
                        <span className="font-small text-muted">
                          By <a>{item.brand?.brand_name}</a>
                        </span>
                      </div>
                      <div className="product-card-bottom">
                        <div className="product-price">
                        {
                          (item.discount && item.discount > 0) ?
                          <>
                            <span>${ ((item.discount_type == 'Percentage') ? item.sales_price - (item.sales_price * item.discount / 100)  : (item.discount_type == 'Fixed') ? item.sales_price - item.discount :  item.sales_price).toFixed(2)} </span>
                            <span className="old-price">${item.sales_price.toFixed(2)}</span>
                          </>  
                          :
                          <span>${item.sales_price.toFixed(2)} </span>
                        }
                        </div>
                        <div className="add-cart">
                          <a className="add" href="" onClick={(e) => handleAddToCart(item,e)}>
                            <i className="fi-rs-shopping-cart mr-5" />
                            Add{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>        
                     );
                  })
               }
              </div>
              {/*End product-grid-4*/}
            </div>
            {/*En tab one*/}
          </div>
          {/*End tab-content*/}
        </div>
      </section>
      {/*Products Tabs*/}
      
      
      <section className="product-tabs section-padding position-relative">
        <div className="container">
          <div className="section-title style-2">
            <h3>Popular Products</h3>
            {/* <ul className="nav nav-tabs links" style={{display:'none'}} id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="nav-tab-one"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-one"
                  type="button"
                  role="tab"
                  aria-controls="tab-one"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-two"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-two"
                  type="button"
                  role="tab"
                  aria-controls="tab-two"
                  aria-selected="false"
                >
                  Milks &amp; Dairies
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-three"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-three"
                  type="button"
                  role="tab"
                  aria-controls="tab-three"
                  aria-selected="false"
                >
                  Coffes &amp; Teas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-four"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-four"
                  type="button"
                  role="tab"
                  aria-controls="tab-four"
                  aria-selected="false"
                >
                  Pet Foods
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-five"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-five"
                  type="button"
                  role="tab"
                  aria-controls="tab-five"
                  aria-selected="false"
                >
                  Meats
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-six"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-six"
                  type="button"
                  role="tab"
                  aria-controls="tab-six"
                  aria-selected="false"
                >
                  Vegetables
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="nav-tab-seven"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-seven"
                  type="button"
                  role="tab"
                  aria-controls="tab-seven"
                  aria-selected="false"
                >
                  Fruits
                </button>
              </li>
            </ul> */}
          </div>
          {/*End nav-tabs*/}
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="tab-one"
              role="tabpanel"
              aria-labelledby="tab-one"
            >
              <div className="row product-grid-4">
               {
                  topProducts.map((item, index) => {
                     return (
                        <div key={index} className="col-lg-1-5 col-md-4 col-12 col-sm-6 inner-product-grid">
                  <div className="product-cart-wrap mb-30">
                    <div className="product-img-action-wrap">
                      <div className="product-img product-img-zoom">
                        <Link to={"/product/" + item.id + "/" + item.item_name}>
                          <img
                            className="default-img"
                            src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                            alt=""
                          />
                          {/* <img
                            className="hover-img"
                            src="/images/shop/product-1-2.jpg"
                            alt=""
                          /> */}
                        </Link>
                      </div>
                      <div className="product-action-1">
                        <a
                          aria-label="Add To Wishlist"
                          className="action-btn"
                          href=""
                          onClick={(e) => { toggleWishlist(item,e)}}
                        >
                          {
                            wishlistItems.includes(item.id) ?
                            <i className="wisilist-fill">&#10084;</i>
                            :
                            <i className="fi-rs-heart" /> 
                          }
                        </a>
                       {/*  <a
                          aria-label="Compare"
                          className="action-btn"
                          href="shop-compare.html"
                        >
                          <i className="fi-rs-shuffle" />
                        </a>
                        <a
                          aria-label="Quick view"
                          className="action-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#quickViewModal"
                        >
                          <i className="fi-rs-eye" />
                        </a> */}
                      </div>
                      <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">Hot</span>
                      </div>
                    </div>
                    <div className="product-content-wrap">
                      <div className="product-category">
                        <Link to={"/product-category/" + item.category?.slug}>{item.category.category_name}</Link>
                      </div>
                      <h2>
                        <Link to={"/product/" + item.id + "/" + item.item_name}>
                          {item.item_name}
                        </Link>
                      </h2>
                      <div className="product-rate-cover">
                        <div className="product-rate d-inline-block">
                          <div
                            className="product-rating"
                            style={{ width: "90%" }}
                          />
                        </div>
                        <span className="font-small ml-5 text-muted"> (4.0)</span>
                      </div>
                      <div>
                        <span className="font-small text-muted">
                          By <a>{item.brand?.brand_name}</a>
                        </span>
                      </div>
                      <div className="product-card-bottom">
                        <div className="product-price">
                        {
                          (item.discount && item.discount > 0) ?
                          <>
                            <span>${ ((item.discount_type == 'Percentage') ? item.sales_price - (item.sales_price * item.discount / 100)  : (item.discount_type == 'Fixed') ? item.sales_price - item.discount :  item.sales_price).toFixed(2)} </span>
                            <span className="old-price">${item.sales_price.toFixed(2)}</span>
                          </>  
                          :
                          <span>${item.sales_price.toFixed(2)} </span>
                        }
                        </div>
                        <div className="add-cart">
                          <a className="add" href="" onClick={(e) => handleAddToCart(item,e)}>
                            <i className="fi-rs-shopping-cart mr-5" />
                            Add{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>        
                     );
                  })
               }
              </div>
              {/*End product-grid-4*/}
            </div>
            {/*En tab one*/}
          </div>
          {/*End tab-content*/}
        </div>
      </section>
      {/*Products Tabs*/}
      
      {
        is_authenticated ?
        <>
          <PromotedItems items={promotedItems} />
        
          <FrequentlyOrderedItems items={frequentlyOrderedItems} />
        </>
        :
        null
      }
      
      
      {/* <ProductColumns /> */}
      {/*End 4 columns*/}

      <ShopByCategory />
    </main>
    
    );
}


export default Home;