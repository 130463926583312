import { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart, addSelectedItemToCart, retriveItemByIds } from "../../services/ItemService";
import { AppContext } from "../../utils/AppProvider";
import { isLogin } from "../../utils/constant";

const Wishlist = () => {
  const [wishlistItems, setwishlistItems] = useState([]);
  const [selectedItems, setSelectedItem] = useState([]);
  const {  setCartQty, cart_qty, wishlist_qty, setWishlistQty} = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getWishlist();
  }, []);

  const getWishlist = async () => {
    let items = await localStorage.getItem('wishlist_items')

    if (items != null) {
      items = JSON.parse(items);

      let item_ids = items.map((i) => i.id).join(",").toString();

      retriveItemByIds(item_ids)
        .then((response) => {
          response.data.data.map((i) => {
            items.map((wi, index) => {
              if (wi.id == i.id) {
                items[index]['sales_price'] = i.sales_price
                items[index]['purchase_price'] = i.purchase_price
                items[index]['item_name'] = i.item_name
                items[index]['stock'] = i.stock
                items[index]['image'] = i.image
              }
            })

            setwishlistItems(items);
          })
        })
        .catch((error) => {
          console.log("Error", error);
        })

    }
  }

  const clearWishlist = async () => {
    if (wishlistItems.length) {
      await localStorage.removeItem('wishlist_items');
      setwishlistItems([]);
      setSelectedItem([]);
      setWishlistQty(0);
      toast("Wishlist clear successfully", { type: 'success' });
    }
  }

  const toggleWishlist = async (item, e) => {
    e.preventDefault();
    let items = await localStorage.getItem('wishlist_items')
    if (items != null) {
      items = JSON.parse(items);
      let ids = items.map(item => item['id']);

      if (ids.includes(item.id)) {
        let wishlist_items = items.filter((_item) => _item.id != item.id)
        setWishlistQty(wishlist_qty - 1);
        setwishlistItems(wishlist_items);
        localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
        toast("Item Removed from Wishlist", { type: 'success' });
      }
    }
  }

  const handleSelectItem = (e, item) => {
    if (e.target.checked) {
      setSelectedItem([...selectedItems, item]);
    } else {
      setSelectedItem(selectedItems.filter((i) => i.id != item.id))
    }
  }

  const handleSelectItemAll = (e,item) =>{
    if (e.target.checked) {
      setSelectedItem(item);
    }else {
      setSelectedItem([])
    }
  }

  const clearSelected = () => {
    let ids = selectedItems.map((i) => { return i.id });
    let items = wishlistItems.filter((i) => { if (!ids.includes(i.id)) { return i; } })
    setWishlistQty(wishlist_qty - ids.length);
    setwishlistItems(items);
    setSelectedItem([]);
    localStorage.setItem('wishlist_items', JSON.stringify(items));
  }

  const handleAddToCart = (item) => {
    if (isLogin()) {
      let item_id = item.id;
      addItemToCart(item_id)
        .then((response) => {
          let items = wishlistItems.filter((i) => i.id != item_id)

          setwishlistItems(items);
          setWishlistQty(wishlist_qty - 1);
          setCartQty(parseInt(cart_qty) + 1);
          localStorage.setItem('wishlist_items', JSON.stringify(items));
          toast('Item Added To Cart Successfully.', { type: 'success' })
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: 'error' })
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: 'error' })
          else
            toast("Something went wrong", { type: 'error' });
        })
    } else {
      toast('Please login to add item to cart', { type: 'error' });
    }
  }

  const addSelectedToCart = () => {
    let ids = selectedItems.map((i) => i.id);

    if (isLogin()) {
      addSelectedItemToCart(ids)
        .then((response) => {
          let items = wishlistItems.filter((i) => { if (!ids.includes(i.id)) { return i; } })

          setwishlistItems(items);
          setSelectedItem([]);
          setWishlistQty(wishlist_qty - ids.length);
          setCartQty(parseInt(cart_qty) + ids.length);
          localStorage.setItem('wishlist_items', JSON.stringify(items));
          toast('Item Added To Cart Successfully.', { type: 'success' })
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: 'error' })
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: 'error' })
          else
            toast("Something went wrong", { type: 'error' });
        })
    } else {
      toast('Please login to add item to cart', { type: 'error' });
    }
  }

  const addAllItemToCart = () => {
    let ids = wishlistItems.map((i) => i.id);

    if (isLogin()) {
      addSelectedItemToCart(ids)
        .then((response) => {

          setwishlistItems([]);
          setSelectedItem([]);
          setWishlistQty(0);
          setCartQty(parseInt(cart_qty) + ids.length);
          localStorage.setItem('wishlist_items', JSON.stringify([]));
          toast('Item Added To Cart Successfully.', { type: 'success' })
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: 'error' })
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: 'error' })
          else
            toast("Something went wrong", { type: 'error' });
        })
    } else {
      toast('Please login to add item to cart', { type: 'error' });
    }
  }


  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </a>
            <span /> Wishlist
          </div>
        </div>
      </div>
      <div className="container mb-30 mt-50">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="mb-50">
              <h1 className="heading-2 mb-10">Your Wishlist</h1>

            </div>
            <div className="table-responsive shopping-summery">
              <table className="table table-wishlist">
                <thead>
                  <tr className="main-heading">
                    <th className="custome-checkbox start pl-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="exampleCheckbox11"
                        defaultValue=""
                        checked={selectedItems.length == wishlistItems.length ? true : false}
                        onChange={(e) => handleSelectItemAll(e, wishlistItems)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox11"
                      />
                    </th>
                    <th scope="col" colSpan={2}>
                      Product
                    </th>
                    <th scope="col">Price</th>
                    <th scope="col">Stock Status</th>
                    <th scope="col">Action</th>
                    <th scope="col" className="end">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="d-lg-none d-md-none mobile-w-c">
                    <td className="custome-checkbox start pl-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="exampleCheckbox11"
                        defaultValue=""
                        checked={selectedItems.length == wishlistItems.length ? true : false}
                        onChange={(e) => handleSelectItemAll(e, wishlistItems)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheckbox11"
                        style={{float:'left',marginLeft:"-10px"}}
                      >All</label>
                    </td>
                  </tr>
                  {
                    wishlistItems.length > 0 ?
                      wishlistItems.map((item, index) => {
                        return (
                          <>
                          <tr key={index+1} className="d-lg-none d-md-none mobile-w-c pt-30">
                            <div className="row">
                              <div className="col-6">
                                <td className="image product-thumbnail pt-40">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkbox"
                                  id={"item-ckbx-" + index}
                                  defaultValue=""
                                  checked={selectedItems?.map((i) => { return i.id })?.includes(item.id)}
                                  onChange={(e) => handleSelectItem(e, item)}
                                  style={{float:'left',margin:'0 15px 10px 0'}}
                                />
                                  <Link to={"/product/" + item.id + "/" + item.item_name}>
                                    <img width={90} height={90} src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" loading="lazy" />
                                  </Link>
                                </td>
                              </div>
                              <div className="col-6 align-items-center justify-content-center d-flex">
                                <td className="product-des product-name">
                                  <h6>
                                    <Link to={"/product/" + item.id + "/" + item.item_name}>{item.item_name}</Link>
                                  </h6>
                                  <div className="product-rate-cover">
                                    <div className="product-rate d-inline-block">
                                      <div
                                        className="product-rating"
                                        style={{ width: "90%" }}
                                      />
                                    </div>
                                    <span className="font-small ml-5 text-muted"> (4.0)</span>
                                  </div>
                                </td>
                              </div>
                              <div className="col-6">
                              <td className="price" data-title="Price">
                                <h3 className="text-brand">${item.sales_price}</h3>
                              </td>
                              </div>
                              <div className="col-6">
                                <td className="text-center detail-info" data-title="Stock">
                                  {
                                    item.stock > 0 ?
                                      <span className="stock-status in-stock mb-0"> In Stock </span>
                                      :
                                      <span className="stock-status out-stock mb-0"> Stock Out </span>
                                  }
                                </td>
                              </div>
                              <div className="col-6">
                                <td className="text-right">
                                  <button onClick={() => handleAddToCart(item)} className="btn btn-sm">Add to cart</button>
                                </td>
                              </div>
                              <div className="col-6">
                                <td className="action text-center  whishlist-padding" data-title="Remove">
                                  <a href="" onClick={(e) => toggleWishlist(item, e)} className="text-body">
                                    <i className="fi-rs-trash" />
                                  </a>
                                </td>
                              </div>
                            </div>
                            </tr>

                          <tr key={index} className="pt-30 mobile-whishlist cart-table-lg">
                            <td className="custome-checkbox pl-30">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id={"item-ckbx-" + index}
                                defaultValue=""
                                checked={selectedItems?.map((i) => { return i.id })?.includes(item.id)}
                                onChange={(e) => handleSelectItem(e, item)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"item-ckbx-" + index}
                              />
                            </td>
                            <td className="image product-thumbnail pt-40">
                              <Link to={"/product/" + item.id + "/" + item.item_name}>
                                <img width={90} height={90} src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" loading="lazy" />
                              </Link>
                            </td>
                            <td className="product-des product-name">
                              <h6>
                                <Link to={"/product/" + item.id + "/" + item.item_name}>{item.item_name}</Link>
                              </h6>
                              <div className="product-rate-cover">
                                <div className="product-rate d-inline-block">
                                  <div
                                    className="product-rating"
                                    style={{ width: "90%" }}
                                  />
                                </div>
                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                              </div>
                            </td>
                            <td className="price" data-title="Price">
                              <h3 className="text-brand">${item.sales_price}</h3>
                            </td>
                            <td className="text-center detail-info" data-title="Stock">
                              {
                                item.stock > 0 ?
                                  <span className="stock-status in-stock mb-0"> In Stock </span>
                                  :
                                  <span className="stock-status out-stock mb-0"> Stock Out </span>
                              }
                            </td>
                            <td className="text-right" data-title="Cart">
                              <button onClick={() => handleAddToCart(item)} className="btn btn-sm">Add to cart</button>
                            </td>
                            <td className="action text-center" data-title="Remove">
                              <a href="" onClick={(e) => toggleWishlist(item, e)} className="text-body">
                                <i className="fi-rs-trash" />
                              </a>
                            </td>
                          </tr>
                          </>
                        )
                      })
                      :
                      <tr><td style={{ textAlign: 'center' }} colSpan={8}> <h6> No Items Available </h6></td></tr>
                  }
                </tbody>
              </table>
              <div style={{ float: 'right' }}>
                <button type="button" className="button btn-sm" disabled={wishlistItems.length <= 0} style={{ opacity: wishlistItems.length <= 0 ? '0.7' : '1' }} name="tinvwl-action-product_selected" value="product_selected" onClick={() => clearSelected()} >Remove Selected Items</button>
                <button type="button" className="button btn-sm m-2" disabled={wishlistItems.length <= 0} style={{ opacity: wishlistItems.length <= 0 ? '0.7' : '1' }} name="tinvwl-action-product_selected" value="product_selected" onClick={() => clearWishlist()} >Empty Wishlist</button>
                <button className="button btn-sm" onClick={() => addSelectedToCart()} disabled={wishlistItems.length <= 0} style={{ opacity: wishlistItems.length <= 0 ? '0.7' : '1' }} type="button" name="tinvwl-action-product_selected" value="product_selected" >Add Selected to Cart</button>
                <button className="button btn-sm m-2" onClick={() => addAllItemToCart()} disabled={wishlistItems.length <= 0} style={{ opacity: wishlistItems.length <= 0 ? '0.7' : '1' }} type="button" name="tinvwl-action-product_all" value="product_all" >Add All to Cart</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}


export default Wishlist;