import './App.css';

import './assets/css/sass/main.scss';
import './assets/css/main.css';
import './assets/css/theme-dark.css';
import './assets/css/b2b-custom.css';
import './assets/css/responsive.css';
import './assets/css/plugins/slider-range.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import Index from './layout/Index';

function App() {
  return (
    <Router>
      <ToastContainer pauseOnFocusLoss={false} />
      <Index></Index>
    </Router>
  );
}

export default App;
