import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { retriveAccountDetails } from "../../services/CustomerService";
import Navigation from "./Navigation";

const Dashboard = () => {
  const [customer,setCustomer] = useState({});
  useEffect(()=>{
    window.scrollTo(0, 0);
    getAccountDetails();
  },[]);

  const getAccountDetails = async () => {
    await retriveAccountDetails().then((response) => {
      setCustomer(response.data.data);
    }).catch((error) => {
      console.log("Error", error);
    })
  }


  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Dashboard
          </div>
        </div>
      </div>
      <div className="page-content pt-150 my-account-details pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content">
                    <div
                      className="tab-pane fade active show"
                      id="dashboard"
                      role="tabpanel"
                      aria-labelledby="dashboard-tab"
                    >
                      <div className="card">
                        <div className="card-header">
                          <h3 className="mb-0">Hello {customer?.name}!</h3>
                        </div>
                        <div className="card-body">
                          <p>
                            From your account dashboard. you can easily check &amp;
                            view your <Link to="/my-account/orders" className="view-text">recent orders</Link>,<br />
                            manage your{" "}
                            <Link to="/my-account/addresses" className="view-text">shipping and billing addresses</Link> and{" "}
                            <Link to="/my-account/account-details" className="view-text">edit your password and account details.</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  );
}

export default Dashboard;