import { Link } from "react-router-dom";

const Banner = () => {
    return (
        <section className="banners mb-25">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-4 col-md-6">
              <div className="banner-img">
                <img src="/images/banner/banner-1-11.jpg" alt="" />
                {/* <div className="banner-text">
                  <h4>
                    Everyday Fresh &amp; <br />
                    Clean with Our
                    <br />
                    Products
                  </h4>
                  <Link href="" className="btn btn-xs">
                    Shop Now <i className="fi-rs-arrow-small-right" />
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="banner-img">
                <img src="/images/banner/banner-2-2.jpg" alt="" />
                {/* <div className="banner-text">
                  <h4>
                    Make your Breakfast
                    <br />
                    Healthy and Easy
                  </h4>
                  <Link href="" className="btn btn-xs">
                    Shop Now <i className="fi-rs-arrow-small-right" />
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 d-md-none d-lg-flex">
              <div className="banner-img mb-sm-0">
                <img src="/images/banner/banner-3-3.jpg" alt="" />
                {/* <div className="banner-text">
                  <h4>
                    The best Organic <br />
                    Products Online
                  </h4>
                  <Link href="" className="btn btn-xs">
                    Shop Now <i className="fi-rs-arrow-small-right" />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Banner;