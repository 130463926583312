import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";
import { getAllStates } from "../../services/StateService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";

const Register = () => {
    const [securityCode,setSecurityCode] = useState('');
    const [states, setStates] = useState([]);
    const [isAgree, setIsAgree] = useState(false);
    const [state, setState] = useState({
        name: '',
        email: '',
        company_name: '',
        address: '',
        city: '',
        state_id: '',
        tax_id: '',
        password: '',
        confirm_password: '',
        phone:'',
        code:''
    });
    const { setIsAuthenticated } = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        getStates();
        setSecurityCode(randomSecurityCode());
    }, []);

    const getStates = async () => {
        await getAllStates().then((response) => {
            setStates(response.data);
        }).catch((error) => {

        });
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        setState({ ...state, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateInput()) {
            await register(state).then((response) => {
                localStorage.setItem('is_authenticated', 1);
                localStorage.setItem('token', response.data.data.token);
                setIsAuthenticated(1);
                toast('Customer Registered Successfully', { type: 'success' })

                navigate("/");
            }).catch((error) => {
                if (error.response.status == 422)
                    toast(error.response.data.error, { type: 'error' })
                else if (error.response.status == 500)
                    toast(error.response.data.message, { type: 'error' })
                else
                    toast("Something went wrong", { type: 'error' });
            });
        }
    }

    const validateInput = () => {
        if (state.name == '') {
            toast("Please enter name.", {
                type: 'error'
            });
            return false
        }
        if (state.email == '') {
            toast("Please enter email.", {
                type: 'error'
            });
            return false
        }
        if (state.company_name == '') {
            toast("Please enter company name.", {
                type: 'error'
            });
            return false
        }
        if (state.password == '') {
            toast("Please enter password.", {
                type: 'error'
            });
            return false
        }
        if(state.confirm_password == ''){
            toast("Please enter confirm password.",{
                type:'error'
            });
            return false
        }

        if(state.confirm_password !== state.password){
            toast("Password and confirm password does not match.",{
                type:'error'
            });
            return false
        }
        
        if(!isAgree){
            toast("Please accept the terms and conditions.",{
                type:'error'
            });
            return false
        }
        
        if(state.code != securityCode){
            toast("Security code does not match",{
                type:'error'
            });
            return false
        }

        return true;
    }

    return (
        <main className="main pages">
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/" rel="nofollow">
                            <i className="fi-rs-home mr-5" />
                            Home
                        </Link>
                        <span /> Registration
                    </div>
                </div>
            </div>
            <div className="page-content register-page pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                            <form method="post">
                            <div className="row">
                                <div className="heading_s1">
                                    <h1 className="mb-5">Create an Account</h1>
                                    <p className="mb-30">
                                        Already have an account?{" "}
                                        <Link to="/login" className="view-text">Login</Link>
                                    </p>
                                </div>
                                <div className="login_wrap widget-taber-content background-white">
                                    <div className="padding_eight_all w-100 bg-white d-lg-inline-flex">
                                        <div className="col-xl-6 m-2">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="name"
                                                    value={state.name}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Name *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="email"
                                                    value={state.email}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Email *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    required=""
                                                    type="password"
                                                    name="password"
                                                    value={state.password}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Password *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    required=""
                                                    type="password"
                                                    name="confirm_password"
                                                    value={state.confirm_password}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Confirm password *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="company_name"
                                                    value={state.company_name}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Company Name *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <textarea
                                                    style={{height:'100px'}}
                                                    required=""
                                                    name="address"
                                                    rows={2}
                                                    value={state.address}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Address"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 m-2">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="city"
                                                    value={state.city}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="City"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control" name="state_id" value={state.state_id} onChange={(e)=> handleInputChange(e)} >
                                                <option value="">Select State</option>
                                                {
                                                    states.map((state,index)=>{
                                                        return <option key={index} value={state.id}> {state.name} </option>
                                                    })
                                                }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="tax_id"
                                                    value={state.tax_id}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Tax ID"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="phone"
                                                    value={state.phone}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Phone"
                                                />
                                            </div>
                                            <div className="login_footer form-group">
                                                <div className="chek-form">
                                                    <input
                                                        type="text"
                                                        required=""
                                                        name="code"
                                                        value={state.code}
                                                        onChange={(e) => handleInputChange(e)}
                                                        placeholder="Security code *"
                                                    />
                                                </div>
                                                <span className="security-code">
                                                    {
                                                        securityCode.split("").map((num,index) => {
                                                            return index == 0 ? <b key={index} className="text-new">{num}</b>
                                                            : index == 1 ? <b key={index} className="text-hot">{num}</b>
                                                            : index == 2 ? <b key={index} className="text-sale">{num}</b>
                                                            : <b key={index} className="text-best">{num}</b>;
                                                        })
                                                    }
                                                </span>
                                            </div>
                                            
                                            <div className="login_footer form-group mb-50">
                                                <div className="chek-form">
                                                    <div className="custome-checkbox">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="checkbox"
                                                            id="exampleCheckbox12"
                                                            defaultValue=""
                                                            checked={isAgree}
                                                            onChange={(e) => (e.target.checked) ? setIsAgree(true) : setIsAgree(false)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleCheckbox12"
                                                        >
                                                            <span>I agree to terms &amp; Policy.</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <Link to="/privacy-policy">
                                                    <i className="fi-rs-book-alt mr-5 text-muted" />
                                                    Lean more
                                                </Link>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg">
                                    <div className="text-center">
                                        <div className="form-group mb-30">
                                            <button
                                                type="submit"
                                                className="btn btn-fill-out btn-block hover-up font-weight-bold"
                                                name="login"
                                                onClick={(e) => handleSubmit(e)}
                                            >
                                                Submit &amp; Register
                                            </button>
                                        </div>
                                        <p className="font-xs text-muted">
                                            <strong>Note:</strong>Your personal data will be used to
                                            support your experience throughout this website, to manage
                                            access to your account, and for other purposes described
                                            in our privacy policy
                                        </p>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default Register;