import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { retriveAccountDetails, saveAccountDetails } from "../../services/CustomerService";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

const AccountDetails = () => {
  const [details, setDetails] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getAccountDetails();
  }, []);

  const getAccountDetails = async () => {
    await retriveAccountDetails().then((response) => {
      setDetails(response.data.data);
    }).catch((error) => {
      console.log("Error", error);
    })
  }

  const handleDetailsChange = (e) => {
    let { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  }

  const handleSubmit = async () => {
    if (validateInput()) {
      await saveAccountDetails(details).then((response) => {
        toast("Details Updated Successfully.", { type: 'success' });
        setDetails({...details,password_current:'', new_password:'', new_password_confirmation:''});
      }).catch((error) => {
        console.log("Error", error);
        if (error.response.status == 422)
          toast(error.response.data.error, { type: 'error' })
        else if (error.response.status == 500)
          toast(error.response.data.message, { type: 'error' })
        else
          toast("Something went wrong", { type: 'error' });
      })

    }
  }

  const validateInput = () => {
    if (details.name == '') {
      toast("Please enter name.", {
        type: 'error'
      });
      return false
    }
    if (details.email == '') {
      toast("Please enter email.", {
        type: 'error'
      });
      return false
    }

    if (details.company_name == '') {
      toast("Please enter company name.", {
        type: 'error'
      });
      return false
    }

    if (details.phone == '') {
      toast("Please enter phone.", {
        type: 'error'
      });
      return false
    }

    if (details.password_current != '') {
      if (!details.hasOwnProperty('new_password') || details.new_password == '') {
        toast("Please enter new password.", {
          type: 'error'
        });
        return false;
      }
      if (!details.hasOwnProperty('new_password_confirmation') || details.new_password_confirmation == '') {
        toast("Please confirm password .", {
          type: 'error'
        });
        return false;
      }

      if (details.new_password != details.new_password_confirmation) {
        toast("Password do not match .", {
          type: 'error'
        });
        return false;
      }
    }

    return true;
  }

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Account Details
          </div>
        </div>
      </div>
      <div className="page-content pt-150 my-account-details pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content">
                    
                    <div
                      className="tab-pane fade active show"
                      id="account-detail"
                      role="tabpanel"
                      aria-labelledby="account-detail-tab"
                    >
                      <div className="card">
                        <div className="card-header">
                          <h5>Account Details</h5>
                        </div>
                        <div className="card-body">
                          <form method="post" name="enq">
                            <div className="row">
                              <div className="form-group col-md-12">
                                <label>
                                  First Name <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="name"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.name}
                                  type="text"
                                />
                              </div>
                            
                              <div className="form-group col-md-12">
                                <label>
                                  Company Name <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="company_name"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.company_name}
                                  type="text"
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <label>
                                  Email Address <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="email"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.email}
                                  type="email"
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <label>
                                  Phone <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="phone"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.phone}
                                  type="text"
                                />
                              </div>
                              <h5>Password change</h5>
                              <span>(leave blank to leave unchanged)</span>
                              <div className="form-group col-md-12">
                                <label>
                                  Current Password{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="password_current"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.password_current ? details.password_current : ''}
                                  type="password"
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <label>
                                  New Password <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="new_password"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.new_password}
                                  type="password"
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <label>
                                  Confirm Password{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  required=""
                                  className="form-control"
                                  name="new_password_confirmation"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.new_password_confirmation}
                                  type="password"
                                />
                              </div>
                              <div className="col-md-12">
                                <button
                                  type="button"
                                  className="btn btn-fill-out submit font-weight-bold"
                                  name="submit"
                                  value="Submit"
                                  onClick={() => handleSubmit()}
                                >
                                  Save Change
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>


  );
}

export default AccountDetails;