import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { retriveCategories } from "../../services/CategoryService";

const ShopByCategory = () => {
    const [showAllCategory, setShowAllCategory] = useState(false);
    const [categories, setcategories] = useState([]);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        await retriveCategories().then((response) => {
            if (response.data.data) {
                setcategories(response.data.data);
            }
        }).catch((error) => {
            console.log("Error", error.response)
        });
    }

    return (
        <section className="popular-categories section-padding">
            <div className="container">
                <div className="section-title">
                    <div className="title">
                        <h3>Shop by Categories</h3>
                        <a className="show-all" href="" onClick={(e) => { e.preventDefault(); setShowAllCategory(!showAllCategory) }}>
                            {showAllCategory ? 'Less Categories' : 'All Categories'}
                            <i className="fi-rs-angle-right" />
                        </a>
                    </div>
                    <div
                        className="slider-arrow slider-arrow-2 flex-right carausel-8-columns-arrow"
                        id="carausel-8-columns-arrows"
                    />
                </div>
                <div className="carausel-8-columns-cover">
                    <div className="carausel-8-columns row" id="carausel-8-columns">
                        {
                            categories.map((category, index) => {
                                if (!showAllCategory) {
                                    if (index <= 7) {
                                        return (<div key={index} className="card-1  col-sm-6">
                                            <figure className="img-hover-scale overflow-hidden">
                                                <Link to={"/product-category/" + category?.slug}>
                                                    <img src="/images/theme/icons/category-1.svg" alt="" />
                                                </Link>
                                            </figure>
                                            <h6>
                                                <Link to={"/product-category/" + category?.slug}>
                                                    {category.category_name}
                                                </Link>
                                            </h6>
                                        </div>)
                                    }
                                } else {
                                    return (<div className="card-1  col-sm-6">
                                        <figure className="img-hover-scale overflow-hidden">
                                            <Link to={"/product-category/" + category?.slug}>
                                                <img src="/images/theme/icons/category-1.svg" alt="" />
                                            </Link>
                                        </figure>
                                        <h6>
                                            <Link to={"/product-category/" + category?.slug}>
                                                {category.category_name}
                                            </Link>
                                        </h6>
                                    </div>)
                                }


                            })
                        }

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ShopByCategory;