import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import RecentViewProducts from "../../common/RecentViewProducts";
import { addItemToCart, retriveItem } from "../../services/ItemService";
import { Link } from "react-router-dom";
import RelatedProducts from "./RelatedProducts";
import { toast } from "react-toastify";
import { isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const Product = () => {
    const [item, setItem] = useState({});
    const [cartQuantity, setCartQuantity] = useState(1);
    const [wishlistItems, setwishlistItems] = useState([]);
    const { setCartQty, cart_qty, wishlist_qty, setWishlistQty } = useContext(AppContext);
    let date = '';

    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        getWishlist();
        getItem();
        setRecentViewProduct();
        setCartQuantity(1);
    }, [params.id]);


    const getItem = async () => {
        let item_id = params.id
        await retriveItem(item_id).then((response) => {
            if (response.data.data) {
                setItem(response.data.data);
            }
        }).catch((error) => {
            console.log("Error", error.response)
        });
    }

    const getWishlist = async () => {
        let items = await localStorage.getItem('wishlist_items')

        if (items != null) {
            items = JSON.parse(items);
            let wishlist_item_ids = items.map((_item) => { return _item.id })
            setwishlistItems(wishlist_item_ids);
        }
    }

    const toggleWishlist = async (item,e) => {
        e.preventDefault();
        let items = await localStorage.getItem('wishlist_items')
        let set =0;
        let date = new Date();
        date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDay() + ", " + date.getFullYear();

        if (items != null) {
            items = JSON.parse(items);
            let ids = items.map(item => item['id']);

            if (ids.includes(item.id)) {
                let wishlist_items = items.filter((_item) => _item.id != item.id)
                let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })
                set =-1;
                setwishlistItems(wishlist_item_ids);
                localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
                toast("Item Removed from Wishlist", { type: 'success' });
            } else {
                item['wishlist_added_date'] = date;
                items.push(item)
                set =1;
                setwishlistItems([...wishlistItems, item.id])
                localStorage.setItem('wishlist_items', JSON.stringify(items));
                toast("Item added into Wishlist", { type: 'success' });
            }
        } else {
            let temp = [];
            item['wishlist_added_date'] = date;
            temp.push(item)
            set=1;
            setwishlistItems([item.id])
            localStorage.setItem('wishlist_items', JSON.stringify(temp));

            toast("Item added into Wishlist", { type: 'success' });
        }
        setWishlistQty(wishlist_qty + set);
    }

    const setRecentViewProduct = () => {
        let items = localStorage.getItem('recent-view-product');

        if (items != null) {
            items = JSON.parse(items);

            if (!items.includes(params.id)) {
                items.push(params.id);
                localStorage.setItem('recent-view-product', JSON.stringify(items));
            }
        } else {
            items = [params.id];
            localStorage.setItem('recent-view-product', JSON.stringify(items));
        }
    }

    const handleAddToCart = (item) => {
        if (isLogin()) {
            let item_id = item.id;
            addItemToCart(item_id, cartQuantity)
                .then((response) => {
                    setCartQty(parseInt(cart_qty) + cartQuantity);
                    setCartQuantity(1);
                    toast('Item Added To Cart Successfully.', { type: 'success' })
                })
                .catch((error) => {
                    console.log("Error", error);
                    if (error.response.status == 422)
                        toast(error.response.data.message, { type: 'error' })
                    else if (error.response.status == 500)
                        toast(error.response.data.message, { type: 'error' })
                    else
                        toast("Something went wrong", { type: 'error' });
                })
        } else {
            toast('Please login to add item to cart', { type: 'error' });
        }
    }

    return (
        <main className="main">
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/" rel="nofollow">
                            <i className="fi-rs-home mr-5" />
                            Home
                        </Link>
                        <span /> <Link to={"/product-category/" + item.category?.slug}> {item.category?.category_name} </Link>{" "}
                        <span /> {item.item_name}
                    </div>
                </div>
            </div>
            <div className="container mb-30">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        <div className="product-detail accordion-detail">
                            <div className="row mb-50 mt-30">
                                <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                    <div className="detail-gallery">
                                        <span className="zoom-icon">
                                            <i className="fi-rs-search" />
                                        </span>
                                        {/* MAIN SLIDES */}
                                        <div className="product-image-slider slick-initialized slick-slider">
                                            <div className="slick-list draggable">
                                                <div
                                                    className="slick-track"
                                                    style={{
                                                        opacity: 1,
                                                        width: 9675,
                                                        transform: "translate3d(-645px, 0px, 0px)"
                                                    }}
                                                >
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={-1}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-7.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-current slick-active"
                                                        data-slick-index={0}
                                                        aria-hidden="false"
                                                        tabIndex={0}
                                                        style={{ width: 538 }}
                                                    >
                                                        <img
                                                            src={item?.images?.length > 0 ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.images[0] : process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png"}
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide"
                                                        data-slick-index={1}
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-1.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide"
                                                        data-slick-index={2}
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-3.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide"
                                                        data-slick-index={3}
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-4.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide"
                                                        data-slick-index={4}
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-5.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide"
                                                        data-slick-index={5}
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-6.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide"
                                                        data-slick-index={6}
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-7.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={7}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-2.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={8}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-1.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={9}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-3.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={10}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-4.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={11}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-5.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={12}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-6.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                    <figure
                                                        className="border-radius-10 slick-slide slick-cloned"
                                                        data-slick-index={13}
                                                        id=""
                                                        aria-hidden="true"
                                                        tabIndex={-1}
                                                        style={{ width: 645 }}
                                                    >
                                                        <img
                                                            src="assets/imgs/shop/product-16-7.jpg"
                                                            alt="product image"
                                                        />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        {/* THUMBNAILS */}
                                        {
                                            item.images?.length > 1 ?


                                                <div className="slider-nav-thumbnails slick-initialized slick-slider">
                                                    {/*  <button
                                                    type="button"
                                                    className="slick-prev slick-arrow"
                                                    style={{}}
                                                >
                                                    <i className="fi-rs-arrow-small-left" />
                                                </button> */}
                                                    <div className="slick-list draggable">
                                                        <div
                                                            className="slick-track"
                                                            style={{
                                                                opacity: 1,
                                                                // width: 3006,
                                                                // transform: "translate3d(-668px, 0px, 0px)"
                                                            }}
                                                        >
                                                            {
                                                                item.images.map((image, index) => {
                                                                    return (
                                                                        <div
                                                                            className="slick-slide slick-cloned"
                                                                            data-slick-index={-4}
                                                                            id=""
                                                                            key={index}
                                                                            aria-hidden="true"
                                                                            tabIndex={-1}
                                                                            style={{ width: 147 }}
                                                                        >
                                                                            <img
                                                                                src={process.env.REACT_APP_BASE_URL + "/storage/products/" + image}
                                                                                alt="product image"
                                                                            />
                                                                        </div>
                                                                    );
                                                                })
                                                            }


                                                        </div>
                                                    </div>
                                                    {/*  <button
                                                    type="button"
                                                    className="slick-next slick-arrow"
                                                    style={{}}
                                                >
                                                    <i className="fi-rs-arrow-small-right" />
                                                </button> */}
                                                </div>
                                                : null}
                                    </div>
                                    {/* End Gallery */}
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="detail-info pr-30 pl-30">
                                        <span className="stock-status out-stock"> Sale Off </span>
                                        <h2 className="title-detail">
                                            {item.item_name}
                                        </h2>
                                        <div className="product-detail-rating">
                                            <div className="product-rate-cover text-end">
                                                <div className="product-rate d-inline-block">
                                                    <div
                                                        className="product-rating"
                                                        style={{ width: "90%" }}
                                                    />
                                                </div>
                                                <span className="font-small ml-5 text-muted">
                                                    {" "}
                                                    (32 reviews)
                                                </span>
                                            </div>
                                        </div>
                                        <div className="clearfix product-price-cover">
                                            <div className="product-price primary-color float-left">
                                            {
                                                (item.discount && item.discount > 0) ?
                                                <>
                                                    <span className="current-price text-brand">${ ((item.discount_type == 'Percentage') ? item.sales_price - (item.sales_price * item.discount / 100)  : (item.discount_type == 'Fixed') ? item.sales_price - item.discount :  item.sales_price).toFixed(2)}</span>
                                                <span>
                                                    <span className="save-price font-md color3 ml-15">
                                                    {item.discount_type == "Percentage" ? `${item.discount}%` : item.discount} Off
                                                    </span>
                                                    <span className="old-price font-md ml-15">${item.sales_price.toFixed(2)}</span>
                                                </span> </>
                                                :
                                                    <span className="current-price text-brand">${item.sales_price?.toFixed(2)}</span>
                                            }
                                            </div>
                                        </div>
                                        <div className="short-desc mb-30">
                                            <p className="font-lg">
                                                {item.description}
                                            </p>
                                        </div>
                                        <div className="attr-detail attr-size mb-30 d-none">
                                            <strong className="mr-10">Size / Weight: </strong>
                                            <ul className="list-filter size-filter font-small">
                                                <li>
                                                    <a href="#">50g</a>
                                                </li>
                                                <li className="active">
                                                    <a href="#">60g</a>
                                                </li>
                                                <li>
                                                    <a href="#">80g</a>
                                                </li>
                                                <li>
                                                    <a href="#">100g</a>
                                                </li>
                                                <li>
                                                    <a href="#">150g</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="detail-extralink mb-50">
                                            <div className="detail-qty border radius">
                                                <a href="" onClick={(e) => { e.preventDefault(); console.log(e); if(cartQuantity > 1){setCartQuantity(cartQuantity -1)}}} className="qty-down">
                                                    <i className="fi-rs-angle-small-down" />
                                                </a>
                                                <input
                                                    type="text"
                                                    name="quantity"
                                                    className="qty-val"
                                                    min={1}
                                                    onChange={() => console.log('change',cartQuantity)}
                                                    value={cartQuantity}
                                                />
                                                <a href="" onClick={(e) => { e.preventDefault(); if(cartQuantity < item.stock){setCartQuantity(cartQuantity +1)}}} className="qty-up">
                                                    <i className="fi-rs-angle-small-up" />
                                                </a>
                                            </div>
                                            <div className="product-extra-link2">
                                                <button onClick={() => handleAddToCart(item)} type="submit" className="button button-add-to-cart">
                                                    <i className="fi-rs-shopping-cart" />
                                                    Add to cart
                                                </button>
                                                <a
                                                    aria-label="Add To Wishlist"
                                                    className="action-btn hover-up"
                                                    href=""
                                                    onClick={(e) => { toggleWishlist(item,e)}}
                                                >
                                                    {
                                                        wishlistItems.includes(item.id) ?
                                                        <i className="wisilist-fill">&#10084;</i>
                                                        :
                                                        <i className="fi-rs-heart" /> 
                                                    }
                                                </a>
                                                {/*  <a
                                                    aria-label="Compare"
                                                    className="action-btn hover-up"
                                                    href="shop-compare.html"
                                                >
                                                    <i className="fi-rs-shuffle" />
                                                </a> */}
                                            </div>
                                        </div>
                                        <div className="font-xs">
                                            <ul className="mr-50 float-start">
                                                <li className="mb-5">
                                                    Type: <span className="text-brand">{item.category?.category_name}</span>
                                                </li>
                                                <li className="mb-5">
                                                    MFG:<span className="text-brand">
                                                        {(() => {
                                                            date = new Date(item.created_date);
                                                            if (date != null && date != 'Invalid Date') {
                                                                return date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear();
                                                            }
                                                        })()


                                                        }
                                                    </span>
                                                </li>

                                            </ul>
                                            <ul className="float-start">
                                                <li className="mb-5">
                                                    SKU: <a href="#">{item.sku}</a>
                                                </li>
                                                <li className="mb-5 d-none">
                                                    Tags:{" "}
                                                    <a href="#" rel="tag">
                                                        Snack
                                                    </a>
                                                    ,{" "}
                                                    <a href="#" rel="tag">
                                                        Organic
                                                    </a>
                                                    ,{" "}
                                                    <a href="#" rel="tag">
                                                        Brown
                                                    </a>
                                                </li>
                                                <li>
                                                    Stock:
                                                    <span className="in-stock text-brand ml-5">
                                                        {item.stock} Items In Stock
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Detail Info */}
                                </div>
                            </div>

                            <RelatedProducts category_id={item.category?.id} item_id={item.id} />
                            <RecentViewProducts prod_id={item.id} />
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default Product;