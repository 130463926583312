import { useContext } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart, retriveSearchItems } from "../../services/ItemService";
import { AppContext } from "../../utils/AppProvider";
import { isLogin } from "../../utils/constant";

let timeout;
const Search = () => {
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState('Select Filter');
    const [wishlistItems, setwishlistItems] = useState([]);
    const { search, setCartQty,wishlist_qty,setWishlistQty, cart_qty } = useContext(AppContext);
    const [showSortByItemCount, setShowSortByItemCount] = useState(false);
     const [showSortByList, setShowSortByList] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setPage(1);
        setPerPage(10);
        setFilter('');
        if (search != '')
            retriveItems();
        else{
            setItems([]);
            toast('No Search Query Found !!', { type: 'info' });
        }
    }, [search]);
    
    useEffect(() => {
        if (search != '')
            retriveItems();
    }, [page, perPage]);

    useEffect(() => {
        getWishlist();
    }, []);

    useEffect(() => {
        setFilter('');
        setPage(1);
    },[perPage]);

    useEffect(()=>{
        // asceding Order
        if (filter === 'Price: Low to High') {
        const sorted_items = [...items].sort((a, b) => a.purchase_price - b.purchase_price);
        setItems(sorted_items);
        }

        // Desceding Order
        if (filter == 'Price: High to Low') {
        const sorted_items = [...items].sort((a, b) => b.purchase_price - a.purchase_price);
        setItems(sorted_items);
        }

    },[filter]);

    const setPerPageValue = (e, count) => {
        e.preventDefault();
        setPerPage(count);
        setShowSortByItemCount(false);
    }
    const setFilterValue = (e, value) => {
        e.preventDefault();
        setFilter(value);
        setShowSortByList(false);
    }

    const getWishlist = async () => {
        let items = await localStorage.getItem('wishlist_items')
    
        if (items != null) {
          items = JSON.parse(items);
          let wishlist_item_ids = items.map((_item) => { return _item.id })
          setwishlistItems(wishlist_item_ids);
        }
    }

    const retriveItems = () => {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            retriveSearchItems(search, page, perPage).then((response) => {
                if (response?.data) {
                    setItems(response.data.data);
                    clearTimeout(timeout);
                }
            }).catch((error) => {
                console.log("Error", error);
            })
        }, 300);
    }

    const toggleWishlist = async (item, e) => {
        e.preventDefault();
        let items = await localStorage.getItem('wishlist_items')
        let set = 0;
        let date = new Date();
        date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDay() + ", " + date.getFullYear();
    
        if (items != null) {
          items = JSON.parse(items);
          let ids = items.map(item => item['id']);
    
          if (ids.includes(item.id)) {
            let wishlist_items = items.filter((_item) => _item.id != item.id)
            let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })
            set = -1;
            setwishlistItems(wishlist_item_ids);
            localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
            toast("Item Removed from Wishlist", { type: 'success' });
          } else {
            item['wishlist_added_date'] = date;
            items.push(item)
            set = 1;
            setwishlistItems([...wishlistItems, item.id])
            localStorage.setItem('wishlist_items', JSON.stringify(items));
            toast("Item added into Wishlist", { type: 'success' });
          }
        } else {
          let temp = [];
          item['wishlist_added_date'] = date;
          temp.push(item)
          set = 1;
          setwishlistItems([item.id])
          localStorage.setItem('wishlist_items', JSON.stringify(temp));
    
          toast("Item added into Wishlist", { type: 'success' });
        }
        setWishlistQty(wishlist_qty + set);
    }

    const handleAddToCart = (item, e) => {
        e.preventDefault();
        if (isLogin()) {
          let item_id = item.id;
          addItemToCart(item_id)
            .then((response) => {
              setCartQty(parseInt(cart_qty) + 1);
              toast('Item Added To Cart Successfully.', { type: 'success' })
            })
            .catch((error) => {
              console.log("Error", error);
              if (error.response.status == 422)
                toast(error.response.data.message, { type: 'error' })
              else if (error.response.status == 500)
                toast(error.response.data.message, { type: 'error' })
              else
                toast("Something went wrong", { type: 'error' });
            })
        } else {
          toast('Please login to add item to cart', { type: 'error' });
        }
    }
    return (
        <main className="main" style={{ transform: "none" }}>
            <div className="container mb-30" style={{ transform: "none" }}>
                <div className="col-lg-12">
                    <div className="shop-product-fillter">
                        <div className="totall-product">
                            <p>
                            We found <strong className="text-brand">{items.length}</strong> items for you!
                            </p>
                        </div>
                        <div className="sort-by-product-area">
                            <div className="sort-by-cover mr-10">
                                <div className="sort-by-product-wrap" onClick={() => setShowSortByItemCount(!showSortByItemCount)}>
                                    <div className="sort-by">
                                    <span>
                                        <i className="fi-rs-apps" />
                                        Show:
                                    </span>
                                    </div>
                                    <div className="sort-by-dropdown-wrap">
                                    <span>
                                        {" "}
                                        {perPage} <i className="fi-rs-angle-small-down" />
                                    </span>
                                    </div>
                                </div>
                                <div className={"sort-by-dropdown" + (showSortByItemCount ? ' show' : '')}>
                                    <ul>
                                    <li>
                                        <a className={perPage == 10 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 10)} href="">
                                        10
                                        </a>
                                    </li>
                                    <li>
                                        <a href="" className={perPage == 20 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 20)}>20</a>
                                    </li>
                                    <li>
                                        <a href="" className={perPage == 30 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 30)}>30</a>
                                    </li>
                                    <li>
                                        <a href="" className={perPage == 40 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 40)}>40</a>
                                    </li>
                                    <li>
                                        <a href="" className={perPage == 50 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 50)}>50</a>
                                    </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="sort-by-cover mr-25">
                                <div className="sort-by-product-wrap" onClick={() => setShowSortByList(!showSortByList)}>
                                    <div className="sort-by">
                                    <span>
                                        <i className="fi-rs-apps-sort" />
                                        Sort by:
                                    </span>
                                    </div>
                                    <div className="sort-by-dropdown-wrap">
                                    <span>
                                        {" "}
                                        {filter} <i className="fi-rs-angle-small-down" />
                                    </span>
                                    </div>
                                </div>
                                <div className={"sort-price-dropdown sort-by-dropdown" + (showSortByList ? ' show' : '')}>
                                    <ul>
                                    <li>
                                        <a href="" className={filter === 'Select Filter' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Select Filter')}>
                                        Select Filter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="" className={filter === 'Price: Low to High' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Price: Low to High')}>Price: Low to High</a>
                                    </li>
                                    <li>
                                        <a href="" className={filter === 'Price: High to Low' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Price: High to Low')}>Price: High to Low</a>
                                    </li>
                                    <li>
                                        <a href="" className={filter == 'Release Date' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Release Date')}>Release Date</a>
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row product-grid-4">
                {
                    items.length > 0 ?
                    items.map((item, index) => {
                        return (
                            <div key={index} className="col-lg-1-5 col-md-4 col-12 col-sm-6 inner-product-grid">
                                <div className="product-cart-wrap mb-30">
                                    <div className="product-img-action-wrap">
                                    <div className="product-img product-img-zoom">
                                        <Link to={"/product/" + item.id + "/" + item.item_name}>
                                        <img
                                            className="default-img"
                                            src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                            alt=""
                                        />
                                        </Link>
                                    </div>
                                    <div className="product-action-1">
                                        <a
                                        aria-label="Add To Wishlist"
                                        className="action-btn"
                                        href=""
                                        onClick={(e) => { toggleWishlist(item,e)}}
                                        >
                                        {
                                            wishlistItems.includes(item.id) ?
                                            <i className="wisilist-fill">&#10084;</i>
                                            :
                                            <i className="fi-rs-heart" /> 
                                        }
                                        </a>
                                    </div>
                                    <div className="product-badges product-badges-position product-badges-mrg">
                                        <span className="hot">Hot</span>
                                    </div>
                                    </div>
                                    <div className="product-content-wrap">
                                    <div className="product-category">
                                        <Link to={"/product-category/" + item.category?.slug}>{item.category.category_name}</Link>
                                    </div>
                                    <h2>
                                        <Link to={"/product/" + item.id + "/" + item.item_name}>
                                        {item.item_name}
                                        </Link>
                                    </h2>
                                    <div className="product-rate-cover">
                                        <div className="product-rate d-inline-block">
                                        <div
                                            className="product-rating"
                                            style={{ width: "90%" }}
                                        />
                                        </div>
                                        <span className="font-small ml-5 text-muted"> (4.0)</span>
                                    </div>
                                    <div>
                                        <span className="font-small text-muted">
                                        By <a>{item.brand?.brand_name}</a>
                                        </span>
                                    </div>
                                    <div className="product-card-bottom">
                                        <div className="product-price">
                                        {
                                        (item.discount && item.discount > 0) ?
                                        <>
                                            <span>${ ((item.discount_type == 'Percentage') ? item.sales_price - (item.sales_price * item.discount / 100)  : (item.discount_type == 'Fixed') ? item.sales_price - item.discount :  item.sales_price).toFixed(2)} </span>
                                            <span className="old-price">${item.sales_price.toFixed(2)}</span>
                                        </>  
                                        :
                                        <span>${item.sales_price.toFixed(2)} </span>
                                        }
                                        </div>
                                        <div className="add-cart">
                                        <a className="add" href="" onClick={(e) => handleAddToCart(item,e)}>
                                            <i className="fi-rs-shopping-cart mr-5" />
                                            Add{" "}
                                        </a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>        
                                );
                        })
                        :
                        <div className="d-flex justify-content-center">
                           <h4>Product Not Found!.</h4>
                        </div>
                }
                </div>
                {
                items.length > 0
                    ?
                    <div className="pagination-area mt-20 mb-20 d-flex justify-content-center">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-start">
                        <li className="page-item">
                            <a className={"page-link page-arrow" + (page == 1 ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page - 1) }}>
                            <i className="fi-rs-arrow-small-left" />
                            </a>
                        </li>

                        <li className="page-item active">
                            <a className="page-link page-number" href="#">
                            {page}
                            </a>
                        </li>

                        <li className="page-item">
                            <a className={"page-link page-arrow" + (perPage != items.length ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page + 1) }}>
                            <i className="fi-rs-arrow-small-right" />
                            </a>
                        </li>
                        </ul>
                    </nav>
                    </div>
                    : null
                }
            </div>
        </main>
        
    );
}

export default Search;