import { useEffect } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { logout } from "../../services/AuthService";
import { getCartItemQty } from "../../services/CartService";
import { AppContext } from "../../utils/AppProvider";

const HeaderMiddle = () => {
    const navigate = useNavigate();
    const { setSearch, setCartQty, cart_qty, wishlist_qty, setWishlistQty, is_authenticated, setIsAuthenticated,setShowMobileSearch } = useContext(AppContext);

    useEffect(async() => {
        if (is_authenticated == 1) {
            getCartItemQty()
                .then((response) => {
                    if (response?.data?.data) {
                        setCartQty(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("Error", error);
                })
        } else {
            setCartQty(0);
        }
    }, [is_authenticated]);

    useEffect(async() => {
        let whishlist_count = 0;
        let items = await localStorage.getItem('wishlist_items')
        if (items != null) {
            items = JSON.parse(items);
            whishlist_count = items.length;
            setWishlistQty(items?.length);
        }
    },[]);

    const handleLogout = async (e) => {
        e.preventDefault();
        await logout().then(() => {
           localStorage.removeItem('is_authenticated');
           localStorage.removeItem('token');
           setIsAuthenticated(0);
  
           navigate('/');
        }).catch((error) => {
           if (error.response.status == 422)
              toast(error.response.data.error, { type: 'error' })
           else if (error.response.status == 500)
              toast(error.response.data.message, { type: 'error' })
           else
              toast("Something went wrong", { type: 'error' });
        })
  
     }
    const handleSearch = (e) => {
        e.preventDefault();
        setShowMobileSearch(false);
        navigate(`/products`);
    }
    return (
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
            <div className="container">
                <div className="header-wrap">
                    <div className="logo logo-width-1">
                        <Link to="/">
                            <img src="/images/rk-white-logo.png" alt="logo" style={{width:'80px',minWidth:'100%'}} />
                        </Link>
                    </div>
                    <div className="header-right">
                        <div className="search-style-2">
                            <form action="" onSubmit={(e)=> handleSearch(e) } role="search" method="get">
                                <input defaultValue="" onChange={(e) =>  setSearch(e.target.value)} type="search" name="s"
                                placeholder="Search for products..." autoComplete="off"  />
                            </form>
                        </div>
                        <div className="header-action-right">
                            <div className="header-action-2">
                                {/* <div className="header-action-icon-2">
                                    <a href="#shop-compare.html">
                                        <img
                                            className="svgInject"
                                            alt="Nest"
                                            src="/images/theme/icons/icon-compare.svg"
                                        />
                                        <span className="pro-count blue">3</span>
                                    </a>
                                    <a href="#shop-compare.html">
                                        <span className="lable ml-0">Compare</span>
                                    </a>
                                </div> */}
                                <div className="header-action-icon-2">
                                    <Link to="/wishlist">
                                        <img
                                            className="svgInject"
                                            alt="Nest"
                                            src="/images/theme/icons/icon-heart.svg"
                                        />
                                        <span className="pro-count blue"> {wishlist_qty}</span>
                                    </Link>
                                    <Link to="/wishlist">
                                        <span className="lable">Wishlist</span>
                                    </Link>
                                </div>
                                <div className="header-action-icon-2">
                                    <Link className="/cart" to="/cart">
                                        <img alt="Nest" src="/images/theme/icons/icon-cart.svg" />
                                        <span className="pro-count blue">{cart_qty}</span>
                                    </Link>
                                    <Link to="/cart">
                                        <span className="lable">Cart</span>
                                    </Link>
                                    <div className="cart-dropdown-wrap cart-dropdown-hm2" style={{ display: 'none' }}>
                                        <ul>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <a href="#shop-product-right.html">
                                                        <img
                                                            alt="Nest"
                                                            src="/images/shop/thumbnail-3.jpg"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4>
                                                        <a href="#shop-product-right.html">Daisy Casual Bag</a>
                                                    </h4>
                                                    <h4>
                                                        <span>1 × </span>$800.00
                                                    </h4>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <a href="##">
                                                        <i className="fi-rs-cross-small" />
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <a href="#shop-product-right.html">
                                                        <img
                                                            alt="Nest"
                                                            src="/images/shop/thumbnail-2.jpg"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4>
                                                        <a href="#shop-product-right.html">Corduroy Shirts</a>
                                                    </h4>
                                                    <h4>
                                                        <span>1 × </span>$3200.00
                                                    </h4>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <a href="##">
                                                        <i className="fi-rs-cross-small" />
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="shopping-cart-footer">
                                            <div className="shopping-cart-total">
                                                <h4>
                                                    Total <span>$4000.00</span>
                                                </h4>
                                            </div>
                                            <div className="shopping-cart-button">
                                                <a href="#shop-cart.html" className="outline">
                                                    View cart
                                                </a>
                                                <a href="#shop-checkout.html">Checkout</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    is_authenticated ?
                                        <div className="header-action-icon-2">
                                            <Link to="/my-account">
                                                <img
                                                    className="svgInject"
                                                    alt="Nest"
                                                    src="/images/theme/icons/icon-user.svg"
                                                />
                                            </Link>
                                            <Link to="/my-account">
                                                <span className="lable ml-0">Account</span>
                                            </Link>
                                            <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                                <ul>
                                                    <li>
                                                        <Link to="/my-account">
                                                            <i className="fi fi-rs-user mr-10" />
                                                            My Account
                                                        </Link>
                                                    </li>
                                                    {/*  <li>
                                                <a href="#page-account.html">
                                                    <i className="fi fi-rs-location-alt mr-10" />
                                                    Order Tracking
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#page-account.html">
                                                    <i className="fi fi-rs-label mr-10" />
                                                    My Voucher
                                                </a>
                                            </li> */}
                                                    <li>
                                                        <Link to="/wishlist">
                                                            <i className="fi fi-rs-heart mr-10" />
                                                            My Wishlist
                                                        </Link>
                                                    </li>
                                                    {/*   <li>
                                                <a href="#page-account.html">
                                                    <i className="fi fi-rs-settings-sliders mr-10" />
                                                    Setting
                                                </a>
                                            </li> */}
                                                    <li>
                                                        <a href="" onClick={(e) => handleLogout(e)}>
                                                            <i className="fi fi-rs-sign-out mr-10" />
                                                            Sign out
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <Link to="/login" className="btn btn-sm">Sign In</Link>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default HeaderMiddle;