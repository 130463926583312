import { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart, retriveRelatedItems } from "../../services/ItemService";
import { isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";


const RelatedProducts = (props) => {
    const [items, setItems] = useState([]);
    const [wishlistItems, setwishlistItems] = useState([]);
    const { setCartQty, cart_qty, wishlist_qty, setWishlistQty } = useContext(AppContext);

    useEffect(() => {
        getRelatedItems();
        getWishlist();
    }, [props]);

    const getRelatedItems = async () => {
        await retriveRelatedItems(props.category_id, props.item_id).then((response) => {
            if (response.data.data) {
                setItems(response.data.data);
            }
        }).catch((error) => {
            console.log("Error", error.response)
        });
    }

    const getWishlist = async () => {
        let items = await localStorage.getItem('wishlist_items')

        if (items != null) {
            items = JSON.parse(items);
            let wishlist_item_ids = items.map((_item) => { return _item.id })
            setwishlistItems(wishlist_item_ids);
        }
    }

    const toggleWishlist = async (item, e) => {
        e.preventDefault();
        let items = await localStorage.getItem('wishlist_items')
        let set =0;
        let date = new Date();
        date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDay() + ", " + date.getFullYear();

        if (items != null) {
            items = JSON.parse(items);
            let ids = items.map(item => item['id']);

            if (ids.includes(item.id)) {
                let wishlist_items = items.filter((_item) => _item.id != item.id)
                let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })
                set =-1;
                setwishlistItems(wishlist_item_ids);
                localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
                toast("Item Removed from Wishlist", { type: 'success' });
            } else {
                item['wishlist_added_date'] = date;
                items.push(item)
                set=1;
                setwishlistItems([...wishlistItems, item.id])
                localStorage.setItem('wishlist_items', JSON.stringify(items));
                toast("Item added into Wishlist", { type: 'success' });
            }
        } else {
            let temp = [];
            item['wishlist_added_date'] = date;
            temp.push(item)
            set=1;
            setwishlistItems([item.id])
            localStorage.setItem('wishlist_items', JSON.stringify(temp));

            toast("Item added into Wishlist", { type: 'success' });
        }
        setWishlistQty(wishlist_qty + set);
    }

    const handleAddToCart = (item,e) => {
        e.preventDefault();
        if (isLogin()) {
            let item_id = item.id;
            addItemToCart(item_id)
                .then((response) => {
                    setCartQty(parseInt(cart_qty) + 1);
                    toast('Item Added To Cart Successfully.', { type: 'success' })
                })
                .catch((error) => {
                    console.log("Error", error);
                    if (error.response.status == 422)
                        toast(error.response.data.message, { type: 'error' })
                    else if (error.response.status == 500)
                        toast(error.response.data.message, { type: 'error' })
                    else
                        toast("Something went wrong", { type: 'error' });
                })
        } else {
            toast('Please login to add item to cart', { type: 'error' });
        }
    }

    return (
        items.length > 0 ?
            <div className="row mt-60">
                <div className="col-12">
                    <h2 className="section-title style-1 mb-30">Related products</h2>
                </div>
                <div className="col-12">
                    <div className="row related-products product-grid-4">
                        {
                            items.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-md-4 col-12 col-sm-6 inner-product-grid">
                                        <div className="product-cart-wrap hover-up mb-20">
                                            <div className="product-img-action-wrap">
                                                <div className="product-img product-img-zoom">
                                                    <Link to={"/product/" + item.id + "/" + item.item_name}>
                                                        <img
                                                            className="default-img"
                                                            src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                                            alt=""
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="product-action-1">

                                                    <a
                                                        aria-label="Add To Wishlist"
                                                        className="action-btn small hover-up"
                                                        href=""
                                                        tabIndex={0}
                                                        onClick={(e) => { toggleWishlist(item,e)}}
                                                    >
                                                    {                        
                                                        wishlistItems.includes(item.id) ?
                                                        <i className="wisilist-fill">&#10084;</i>
                                                        :
                                                        <i className="fi-rs-heart" /> 
                                                    }
                                                    </a>

                                                </div>
                                                <div className="product-badges product-badges-position product-badges-mrg">
                                                    <span className="hot">Hot</span>
                                                </div>
                                            </div>
                                            <div className="product-content-wrap">
                                                <h2>
                                                    <Link to={"/product/" + item.id + "/" + item.item_name}>
                                                        {item.item_name}
                                                    </Link>
                                                </h2>
                                                <div className="rating-result" title="90%">
                                                    <span> </span>
                                                </div>
                                                <div className="product-card-bottom">
                                                    <div className="product-price">
                                                    {
                                                        (item.discount && item.discount > 0) ?
                                                        <>
                                                        <span>${ ((item.discount_type == 'Percentage') ? item.sales_price - (item.sales_price * item.discount / 100)  : (item.discount_type == 'Fixed') ? item.sales_price - item.discount :  item.sales_price).toFixed(2)} </span>
                                                        <span className="old-price">${item.sales_price.toFixed(2)}</span>
                                                        </>  
                                                        :
                                                        <span>${item.sales_price.toFixed(2)} </span>
                                                    }
                                                    </div>
                                                    <div className="add-cart"><a className="add" onClick={(e) => handleAddToCart(item,e)} href=""><i className="fi-rs-shopping-cart mr-5"></i>Add </a></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                    </div>
                </div>
            </div>
            : null
    );
}

export default RelatedProducts;