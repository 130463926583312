import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "../../services/AuthService";

const ResetPassword = () => {
    const [state, setState] = useState({
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [isValid, setIsValid] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const email = urlParams.get('email');

        let storedToken = localStorage.getItem('pass-reset-token');
        if (storedToken != null && storedToken === token) {
            setIsValid(true);
            setState({ ...state, ['email']: email });
        } else {
            setIsValid(false);
        }
    }, []);

    const handlePasswordChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateInput()) {
            await resetPassword(state).then((response) => {
                localStorage.removeItem('pass-reset-token');
                toast('Password Reset Sucessfully.', { type: 'success' })
                navigate('/login');
            }).catch((error) => {
                console.log("Error", error.response)
                if (error.response.status == 422)
                    toast(error.response.data.error, { type: 'error' })
                else if (error.response.status == 500)
                    toast(error.response.data.message, { type: 'error' })
                else
                    toast("Something went wrong", { type: 'error' });
            });
        }
    }

    const validateInput = () => {

        if (state.password == '') {
            toast("Please enter password.", {
                type: 'error'
            });
            return false
        }

        if (state.password_confirmation == '') {
            toast("Please enter confirm password.", {
                type: 'error'
            });
            return false
        }

        if (state.password_confirmation !== state.password) {
            toast("Password and confirm password do not match.", {
                type: 'error'
            });
            return false
        }
        return true;
    }

    return (
        <>

            <main className="main pages">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <a href="index.html" rel="nofollow">
                                <i className="fi-rs-home mr-5" />
                                Home
                            </a>
                            <span /> Reset Password
                        </div>
                    </div>
                </div>
                <div className="page-content pt-150 pb-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-8 col-md-12 m-auto">
                                <div className="row">
                                    {
                                        (isValid === true) ?
                                            <>
                                                <div className="heading_s1">
                                                    <img
                                                        className="border-radius-15"
                                                        src="/images/page/reset_password.svg"
                                                        alt=""
                                                    />
                                                    <h2 className="mb-15 mt-15">Set new password</h2>
                                                    <p className="mb-30">
                                                        Please create a new password that you don’t use on any other
                                                        site.
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-8">
                                                    <div className="login_wrap widget-taber-content background-white">
                                                        <div className="padding_eight_all bg-white">

                                                            <form method="post">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="password"
                                                                        required=""
                                                                        name="password"
                                                                        placeholder="Password *"
                                                                        value={state.password}
                                                                        onChange={(e) => handlePasswordChange(e)}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input
                                                                        type="password"
                                                                        required=""
                                                                        name="password_confirmation"
                                                                        placeholder="Confirm you password *"
                                                                        value={state.password_confirmation}
                                                                        onChange={(e) => handlePasswordChange(e)}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-heading btn-block hover-up"
                                                                        name="login"
                                                                        onClick={(e) => handleSubmit(e)}
                                                                    >
                                                                        Reset password
                                                                    </button>
                                                                </div>
                                                            </form>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pl-50">
                                                    <h6 className="mb-15">Password must:</h6>
                                                    <p>Be between 9 and 64 characters</p>
                                                    <p>Include at least tow of the following:</p>
                                                    <ol className="list-insider">
                                                        <li>An uppercase character</li>
                                                        <li>A lowercase character</li>
                                                        <li>A number</li>
                                                        <li>A special character</li>
                                                    </ol>
                                                </div>
                                            </>
                                            : null
                                    }
                                    {
                                        (isValid === false) ?
                                            <div>
                                                <h4 style={{ textAlign: 'center', color: '#cd1111' }}>Can't proceed, Reset password process tempered</h4>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}


export default ResetPassword;