import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateBillingAddress, updateShippingAddress } from "../../services/CustomerService";
import { getAllStates } from "../../services/StateService";
import Navigation from "./Navigation";

const EditAddress = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address, setAddress] = useState(JSON.parse(searchParams.get("add")))
    const [states, setStates] = useState([])
    const [type, setType] = useState(params.type)
    const navigate = useNavigate();


    useEffect(() => {
        getStates();
    }, []);

    const getStates = async () => {
        await getAllStates().then((response) => {
            setStates(response.data);
        }).catch((error) => {

        });
    }

    const handleAddressChange = (e) => {
        let { name, value } = e.target;
        setAddress({ ...address, [name]: value });
    }


    const handleSubmit = async () => {
        if (validateInput()) {
            delete address.state_name;

            if (type == 'billing') {
                await updateBillingAddress(address).then((response) => {
                    toast("Billing Address Updated Successfully.", { type: 'success' });
                }).catch((error) => {
                    console.log("Error", error);
                })
            } else {
                await updateShippingAddress(address).then((response) => {
                    toast("Shipping Address Updated Successfully.", { type: 'success' });
                }).catch((error) => {
                    console.log("Error", error);
                })
            }
        }
    }

    const validateInput = () => {
        if (!address.hasOwnProperty('address') || address.address == '') {
            toast("Please Enter Address.", {
                type: 'error'
            });
            return false
        }
        if (!address.hasOwnProperty('city') || address.city == '') {
            toast("Please Enter City.", {
                type: 'error'
            });
            return false
        }
        if (!address.hasOwnProperty('state_id') || address.state_id == '') {
            toast("Please Select State.", {
                type: 'error'
            });
            return false
        }
        if (!address.hasOwnProperty('pincode') || address.pincode == '') {
            toast("Please Enter Pincode.", {
                type: 'error'
            });
            return false
        }

        return true;
    }

    return (
        <main className="main pages">
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/" rel="nofollow">
                            <i className="fi-rs-home mr-5" />
                            Home
                        </Link>
                        <span /> Addresses
                    </div>
                </div>
            </div>
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 m-auto">
                            <div className="row">
                                <Navigation />
                                <div className="col-md-9">
                                    <div className="tab-content account dashboard-content pl-50">

                                        <div
                                            className="tab-pane fade active show"
                                            id="address"
                                            role="tabpanel"
                                            aria-labelledby="address-tab"
                                        >
                                            <form method="post">
                                                <h3> {type == "shipping" ? 'Shipping' : 'Billing'} address</h3>
                                                <div className="mt-2">
                                                    <div className="woocommerce-address-fields__field-wrapper">
                                                        <p
                                                            className=""
                                                            id="shipping_address_1_field"
                                                            data-priority={50}
                                                        >
                                                            <label htmlFor="shipping_address_1" className="">
                                                                Address&nbsp; *
                                                            </label>
                                                            <span className="woocommerce-input-wrapper">
                                                                <textarea
                                                                    type="text"
                                                                    className="input-text"
                                                                    style={{ height: '70px' }}
                                                                    name="address"
                                                                    rows={2}
                                                                    cols={40}
                                                                    id="shipping_address_1"
                                                                    placeholder="Enter Full Address"
                                                                    value={address.address}
                                                                    onChange={(e) => handleAddressChange(e)}
                                                                    autoComplete="address-line1"
                                                                    data-placeholder="House number and street name"
                                                                > </textarea>
                                                            </span>
                                                        </p>
                                                        {/*  <p
                                                                className="form-row address-field form-row-wide"
                                                                id="shipping_address_2_field"
                                                                data-priority={60}
                                                            >
                                                                <label
                                                                    htmlFor="shipping_address_2"
                                                                    className="screen-reader-text"
                                                                >
                                                                    Apartment, suite, unit, etc.&nbsp;
                                                                    <span className="optional">(optional)</span>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                   
                                                                </span>
                                                            </p> */}
                                                        <p
                                                            className="mt-2"
                                                            id="shipping_city_field"
                                                        >
                                                            <label htmlFor="shipping_city" className="">
                                                                Town / City&nbsp; *
                                                            </label>
                                                            <span className="woocommerce-input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    className="input-text "
                                                                    name="city"
                                                                    id="shipping_city"
                                                                    placeholder=""
                                                                    value={address.city}
                                                                    autoComplete="address-level2"
                                                                    onChange={(e) => handleAddressChange(e)}
                                                                />
                                                            </span>
                                                        </p>
                                                        <p
                                                            className="mt-2"
                                                            id="shipping_state_field"
                                                        >
                                                            <label htmlFor="shipping_state" className="">
                                                                State&nbsp;  *
                                                            </label>
                                                            <span className="woocommerce-input-wrapper">
                                                                <select
                                                                    name="state_id"
                                                                    className="form-control"
                                                                    autoComplete="address-level1"
                                                                    value={address.state_id}
                                                                    onChange={(e) => handleAddressChange(e)}
                                                                >
                                                                    <option value="">Select an option…</option>
                                                                    {
                                                                        states.map((state, index) => {
                                                                            return <option key={index} value={state.id}> {state.name} </option>
                                                                        })
                                                                    }
                                                                </select>

                                                            </span>
                                                        </p>

                                                        <p
                                                            className="mt-2"
                                                            id="shipping_city_field"
                                                        >
                                                            <label htmlFor="shipping_city" className="">
                                                                Pincode&nbsp; *
                                                            </label>
                                                            <span className="woocommerce-input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    className="input-text "
                                                                    name="pincode"
                                                                    id="shipping_city"
                                                                    placeholder=""
                                                                    value={address.pincode}
                                                                    autoComplete="address-level2"
                                                                    onChange={(e) => handleAddressChange(e)}
                                                                />
                                                            </span>
                                                        </p>

                                                    </div>
                                                    <p className="mt-2">
                                                        <button
                                                            type="button"
                                                            className="button"
                                                            style={{ marginRight: '10px' }}
                                                            name="save_address"
                                                            value="Save address"
                                                            onClick={() => handleSubmit()}
                                                        >
                                                            Save address
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="button"
                                                            name="save_address"
                                                            onClick={() => navigate('/my-account/addresses')}
                                                        >
                                                            Back
                                                        </button>

                                                    </p>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default EditAddress;