import Home from "../components/Home/Home"
import Login from "../components/Auth/Login"
import Register from "../components/Auth/Register"
import Product from "../components/Product/Product";
import ProductCategory from "../components/ProductCategory/ProductCategory";
import Wishlist from "../components/Wishlist/Wishlist";
import Cart from "../components/Cart/Cart";
import Dashboard from "../components/MyAccount/Dashboard";
import Address from "../components/MyAccount/Address";
import Orders from "../components/MyAccount/Orders";
import AccountDetails from "../components/MyAccount/AccountDetails";
import EditAddress from "../components/MyAccount/EditAddress";
import Contact from "../components/MyAccount/Contact";
import LostPassword from "../components/Password/LostPassword";
import ResetPassword from "../components/Password/ResetPassword";
import Checkout from "../components/Checkout/Checkout";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import AboutUs from "../components/pages/AboutUs";
import TermsConditions from "../components/pages/TermsConditions";
import Search from "../components/Search/Search";

const INDEX = '/';
const LOGIN = '/login';
const REGISTER = '/register';
const PRODUCT = '/product/:title';
const PRODUCT1 = '/product/:id/:title';
const PRODUCT_CATEGORY = '/product-category/:category';
const PRODUCT_SUBCATEGORY = '/product-category/:category/:subcategory';
const PRODUCT_SEARCH = '/products';
const MY_ACCOUNT = '/my-account';
const MY_ACCOUNT_WITH_SLASH = '/my-account/';
const MY_ORDERS = '/my-account/orders';
const MY_ADDRESSES = '/my-account/addresses';
const EDIT_ADDRESS = '/my-account/edit-address/:type';
const MY_ACCOUNT_DETAILS = '/my-account/account-details';
const WISHLIST = '/wishlist';
const CART = '/cart';
const CONTACT = '/contact';
const LOST_PAAWORD = '/my-account/lost-password';
const RESET_PAAWORD = '/my-account/reset-password';
const CHECKOUT = '/checkout';
const PRIVACY_POLICY = '/privacy-policy';
const ABOUT_US = '/about-us';
const TERMS_AND_CONDITIONS = '/terms-and-conditions';

const routes = [
    {
        path: INDEX,
        exact: true,
        private: false,
        page: {
            component: Home,
            title: 'Home'
        }
    },
    {
        path: LOGIN,
        exact: true,
        private: false,
        page: {
            component: Login,
            title: 'Login'
        }
    },
    {
        path: REGISTER,
        private: false,
        exact: true,
        page: {
            component: Register,
            title: 'Register'
        }
    },
    {
        path: PRODUCT,
        exact: true,
        private: false,
        page: {
            component: Product,
            title: 'Register'
        }
    },
    {
        path: PRODUCT1,
        exact: true,
        private: false,
        page: {
            component: Product,
            title: 'Register'
        }
    },
    {
        path: PRODUCT_CATEGORY,
        exact: true,
        private: false,
        page: {
            component: ProductCategory,
            title: 'Register'
        }
    },
    {
        path: PRODUCT_SUBCATEGORY,
        exact: true,
        private: false,
        page: {
            component: ProductCategory,
            title: 'Register'
        }
    },
    {
        path: PRODUCT_SEARCH,
        exact: true,
        private: false,
        page: {
            component: Search,
            title: 'Search'
        }
    },
    {
        path: MY_ACCOUNT,
        exact: true,
        private: true,
        page: {
            component: Dashboard,
            title: 'My Account'
        }
    },
    {
        path: MY_ACCOUNT_WITH_SLASH,
        exact: true,
        private: true,
        page: {
            component: Dashboard,
            title: 'My Account'
        }
    },
    {
        path: MY_ORDERS,
        exact: true,
        private: true,
        page: {
            component: Orders,
            title: 'My Orders'
        }
    },
    {
        path: MY_ADDRESSES,
        exact: true,
        private: true,
        page: {
            component: Address,
            title: 'My Addresses'
        }
    },
    {
        path: EDIT_ADDRESS,
        exact: true,
        private: true,
        page: {
            component: EditAddress,
            title: 'Edit Addresses'
        }
    },
    {
        path: CONTACT,
        exact: true,
        private: false,
        page: {
            component: Contact,
            title: 'Contact'
        }
    },
    {
        path: MY_ACCOUNT_DETAILS,
        exact: true,
        private: true,
        page: {
            component: AccountDetails,
            title: 'My AccountDetails'
        }
    },
    {
        path: LOST_PAAWORD,
        exact: true,
        private: false,
        page: {
            component: LostPassword,
            title: 'Lost Password'
        }
    },
    {
        path: RESET_PAAWORD,
        exact: true,
        private: false,
        page: {
            component: ResetPassword,
            title: 'Reset Password'
        }
    },
    {
        path: CHECKOUT,
        exact: true,
        private: true,
        page: {
            component: Checkout,
            title: 'Checkout'
        }
    },
    {
        path: WISHLIST,
        exact: true,
        private: false,
        page: {
            component: Wishlist,
            title: 'Wishlist'
        }
    },
    {
        path: CART,
        exact: true,
        private: false,
        page: {
            component: Cart,
            title: 'Cart'
        }
    },
    {
        path: PRIVACY_POLICY,
        exact: true,
        private: false,
        page: {
            component: PrivacyPolicy,
            title: 'Privacy Policy'
        }
    },
    {
        path: ABOUT_US,
        exact: true,
        private: false,
        page: {
            component: AboutUs,
            title: 'About US'
        }
    },
    {
        path: TERMS_AND_CONDITIONS,
        exact: true,
        private: false,
        page: {
            component: TermsConditions,
            title: 'Terms And Condition'
        }
    },
];

export default routes;