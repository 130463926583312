import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createOrder, retriveCartAndCustomer } from "../../services/OrderService";
import { getAllStates } from "../../services/StateService";
import { AppContext } from "../../utils/AppProvider";

const Checkout = () => {
    const [order, setOrder] = useState({
        billing_address: {},
        shipping_address: {},
        items: [],
        subtotal: 0,
        total: 0,
        payment_method: 'Cash On Dilevery',
        note: '',
        hasShippingAddress: false
    });
    const [acceptTAC, setAcceptTAC] = useState(false);
    const [states, setStates] = useState([]);
    const navigate = useNavigate();
    const { setCartQty, cart_qty } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        getDetails();
        getState();
    }, []);

    const getState = async () => {
        await getAllStates().then((response) => {
            setStates(response.data);
        }).catch((error) => {

        });
    }

    const getDetails = async () => {
        await retriveCartAndCustomer().then((response) => {

            if (response?.data?.data) {
                let data = response.data.data

                if (data.status !== 'approved') {
                    toast('Only approved customer can order');
                    navigate('/cart');
                }

                let bill_add = {
                    name: data.name,
                    company_name: data.company_name,
                    address: data.address,
                    city: data.city,
                    state_id: data.state_id,
                    pincode: data.pincode,
                    phone: data.phone,
                    email: data.email
                }

                let shipp_add = {};

                if (data.hasOwnProperty('addresses') && data.addresses.length > 0) {
                    let temp = data.addresses[0];
                    shipp_add = {
                        name: data.name,
                        company_name: data.company_name,
                        address: temp.address,
                        city: temp.city,
                        state_id: temp.state_id,
                        pincode: temp.pincode,
                        // state_name: temp.state?.name,
                        // shipp_add_id: temp.id,
                    }
                }
                let items = [];
                let st = 0;
                items = data.cart_items.map((i, index) => {
                    let price = i.item.sales_price;
                    if(i.item.discount_type == 'Percentage'){
                    price = i.item.sales_price - (i.item.sales_price * i.item.discount / 100)
                    }
                    if(i.item.discount_type == 'Fixed'){
                    price = i.item.sales_price - i.item.discount
                    } 
                    price = parseFloat(price.toFixed(2))
                    st += i.quantity * price;
                    return {
                        item_name: i.item.item_name,
                        item_id: i.item_id,
                        image: i.item.image,
                        quantity: i.quantity,
                        item_price: price,
                        subtotal: i.quantity * price
                    };
                });

                if (items.length <= 0) {
                    navigate('/cart');
                }

                setOrder({
                    ...order,
                    ['billing_address']: bill_add,
                    ['shipping_address']: shipp_add,
                    ['items']: items,
                    ['subtotal']: st,
                    ['total']: st
                });
            }
        }).catch((error) => {
            console.log("Error", error);
        })
    }


    const handleBillingAddChange = (e) => {
        let { name, value } = e.target;
        let bill_add = { ...order.billing_address };
        bill_add[name] = value

        setOrder({ ...order, ['billing_address']: bill_add });
    }

    const handleShippingAddChange = (e) => {
        let { name, value } = e.target;
        let shipp_add = { ...order.shipping_address };
        shipp_add[name] = value

        setOrder({ ...order, ['shipping_address']: shipp_add });
    }

    const handleOrderSubmit = async (e) => {
        e.preventDefault();
        if (validateInput()) {
            await createOrder(order).then((response) => {
                toast("Order Created Successfully.", { type: 'success' });
                setCartQty(0);
                navigate("/my-account/orders");
            }).catch((error) => {
                if (error.response.status == 422)
                    toast(error.response.data.error, { type: 'error' })
                else if (error.response.status == 500)
                    toast(error.response.data.message, { type: 'error' })
                else
                    toast("Something went wrong", { type: 'error' });
            });
        }
    }

    const validateInput = () => {

        // Validate Billing Address
        if (order.billing_address.name == '') {
            toast("Please enter name in billing address.", {
                type: 'error'
            });
            return false
        }
        if (order.billing_address.address == '') {
            toast("Please enter address in billing address.", {
                type: 'error'
            });
            return false
        }
        if (order.billing_address.city == '') {
            toast("Please enter city in billing address.", {
                type: 'error'
            });
            return false
        }
        if (order.billing_address.state_id == '') {
            toast("Please select state in billing address.", {
                type: 'error'
            });
            return false
        }
        if (order.billing_address.pincode == '') {
            toast("Please enter pincode in billing address.", {
                type: 'error'
            });
            return false
        }
        if (order.billing_address.phone == '') {
            toast("Please enter phone.", {
                type: 'error'
            });
            return false
        }
        if (order.billing_address.email == '') {
            toast("Please enter email address.", {
                type: 'error'
            });
            return false
        }

        // Validate Shipping Address If Enabled
        if (order.hasShippingAddress) {
            if (order.shipping_address.name == '') {
                toast("Please enter name in shipping address.", {
                    type: 'error'
                });
                return false
            }
            if (order.shipping_address.address == '') {
                toast("Please enter address in shipping address.", {
                    type: 'error'
                });
                return false
            }
            if (order.shipping_address.city == '') {
                toast("Please enter city in shipping address.", {
                    type: 'error'
                });
                return false
            }
            if (order.shipping_address.state_id == '') {
                toast("Please select state in shipping address.", {
                    type: 'error'
                });
                return false
            }
            if (order.shipping_address.pincode == '') {
                toast("Please enter pincode in shipping address.", {
                    type: 'error'
                });
                return false
            }
        }

        if (!acceptTAC) {
            toast("Please accept Term and Condition.", {
                type: 'error'
            });
            return false
        }

        return true;
    }

    return (
        <main className="main">
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/" rel="nofollow">
                            <i className="fi-rs-home mr-5" />
                            Home
                        </Link>
                        <span /> Checkout
                    </div>
                </div>
            </div>
            <div className="container mb-80 mt-50">
                <div className="row">
                    <div className="col-lg-8 mb-40">
                        <h1 className="heading-2 mb-10">Checkout</h1>
                        <div className="d-flex justify-content-between">
                            <h6 className="text-body">
                                There are <span className="text-brand">{cart_qty}</span> products in your
                                cart
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="row">
                            <h4 className="mb-30">Billing Details</h4>
                            <form method="post">
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <input
                                            type="text"
                                            required=""
                                            name="name"
                                            placeholder="Full name *"
                                            value={order.billing_address?.name}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <input
                                            type="text"
                                            required=""
                                            name="company_name"
                                            placeholder="Company name *"
                                            value={order.billing_address?.company_name}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <input
                                            type="text"
                                            name="address"
                                            required=""
                                            placeholder="Address *"
                                            value={order.billing_address?.address}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="row shipping_calculator">
                                    <div className="form-group col-lg-6">
                                        <input
                                            required=""
                                            type="text"
                                            name="city"
                                            placeholder="City / Town *"
                                            value={order.billing_address?.city}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <div className="custom_select">
                                            <select
                                                className="form-control select-active"
                                                data-select2-id={7}
                                                tabIndex={-1}
                                                style={{ height: '64px' }}
                                                aria-hidden="true"
                                                name="state_id"
                                                value={order.billing_address?.state_id}
                                                onChange={(e) => handleBillingAddChange(e)}
                                            >
                                                <option value="">Select an state</option>
                                                {
                                                    states.map((state, index) => {
                                                        return <option value={state.id}> {state.name} </option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <input
                                            required=""
                                            type="text"
                                            name="pincode"
                                            placeholder="Postcode / ZIP *"
                                            value={order.billing_address?.pincode}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <input
                                            required=""
                                            type="text"
                                            name="phone"
                                            placeholder="Phone *"
                                            value={order.billing_address?.phone}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <input
                                            required=""
                                            type="text"
                                            name="email"
                                            placeholder="Email address *"
                                            value={order.billing_address?.email}
                                            onChange={(e) => handleBillingAddChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-30">
                                    <textarea
                                        rows={5}
                                        placeholder="Additional information"
                                        defaultValue={""}
                                        name="note"
                                        value={order.note}
                                        onChange={(e) => handleBillingAddChange(e)}
                                    />
                                </div>
                                <div className="ship_detail">
                                    <div className="form-group">
                                        <div className="chek-form">
                                            <div className="custome-checkbox">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="checkbox"
                                                    id="differentaddress"
                                                    checked={order.hasShippingAddress}
                                                    onChange={(e) => { let { checked } = e.target; setOrder({ ...order, ['hasShippingAddress']: checked }) }}
                                                />
                                                <label
                                                    className="form-check-label label_info"
                                                    data-bs-toggle="collapse"
                                                    data-target="#collapseAddress"
                                                    href="#collapseAddress"
                                                    aria-controls="collapseAddress"
                                                    htmlFor="differentaddress"
                                                >
                                                    <span>Ship to a different address?</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseAddress"
                                        className={"different_address collapse in" + (order.hasShippingAddress ? ' show' : '')}
                                    >
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="name"
                                                    placeholder="Full name *"
                                                    value={order.shipping_address?.name}
                                                    onChange={(e) => handleShippingAddChange(e)}
                                                />
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <input
                                                    type="text"
                                                    required=""
                                                    name="company_name"
                                                    placeholder="Company name"
                                                    value={order.shipping_address?.company_name}
                                                    onChange={(e) => handleShippingAddChange(e)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    required=""
                                                    placeholder="Address *"
                                                    value={order.shipping_address?.address}
                                                    onChange={(e) => handleShippingAddChange(e)}
                                                />
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    required=""
                                                    placeholder="City"
                                                    value={order.shipping_address?.city}
                                                    onChange={(e) => handleShippingAddChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <div className="custom_select">
                                                    <select
                                                        className="form-control select-active"
                                                        data-select2-id={7}
                                                        tabIndex={-1}
                                                        style={{ height: '64px' }}
                                                        aria-hidden="true"
                                                        name="state_id"
                                                        value={order.shipping_address?.state_id}
                                                        onChange={(e) => handleShippingAddChange(e)}
                                                    >
                                                        <option value="">Select State</option>
                                                        {
                                                            states.map((state, index) => {
                                                                return <option value={state.id}> {state.name} </option>
                                                            })
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <input
                                                    required=""
                                                    type="text"
                                                    name="pincode"
                                                    placeholder="Postcode / ZIP *"
                                                    value={order.shipping_address?.pincode}
                                                    onChange={(e) => handleShippingAddChange(e)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="border p-40 cart-totals ml-30 mb-50">
                            <div className="d-flex align-items-end justify-content-between mb-30">
                                <h4>Your Order</h4>
                                <h6 className="text-muted">Subtotal</h6>
                            </div>
                            <div className="divider-2 mb-30" />
                            <div className="table-responsive order_table checkout">
                                <table className="table no-border">
                                    <tbody>

                                        {
                                            order.items.map((i, index) => {
                                                return (
                                                    <tr>
                                                        <td className="image product-thumbnail">
                                                            <img width={100} src={i?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + i.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"} alt="#" />
                                                        </td>
                                                        <td>
                                                            <h6 className="w-160 mb-5">
                                                                <Link target="_blank" to={"/product/" + i.item_id + "/" + i.item_name} className="text-heading">
                                                                    {i.item_name}
                                                                </Link>
                                                            </h6>
                                                            <div className="product-rate-cover">
                                                                <div className="product-rate d-inline-block">
                                                                    <div
                                                                        className="product-rating"
                                                                        style={{ width: "90%" }}
                                                                    ></div>
                                                                </div>
                                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="text-muted pl-20 pr-20">x {i.quantity}</h6>
                                                        </td>
                                                        <td>
                                                            <h4 className="text-brand">${typeof i.subtotal != 'string' ? i.subtotal?.toFixed(2) : i.subtotal}</h4>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="divider-2 mb-3" />
                            <div className="">
                                <label className="">
                                    <input
                                        type="checkbox"
                                        className=""
                                        style={{ width: '17px', height: '17px', marginRight: '10px' }}
                                        name="terms"
                                        id=""
                                        checked={acceptTAC}
                                        onChange={(e) => setAcceptTAC(e.target.checked)}
                                    />
                                    <span className="">
                                        I have read and agree to the website{" "}
                                        <Link
                                            to="/terms-and-conditions"
                                            target="_blank"
                                            className="view-text"
                                        >
                                            terms and conditions
                                        </Link>
                                    </span>
                                    &nbsp;
                                    <abbr className="required" title="required"> * </abbr>
                                </label>

                            </div>
                            <div className="d-flex align-items-end justify-content-between">
                                <h5>Total</h5>
                                <h5 className="text-muted">{ typeof order.total != 'string' ? order.total?.toFixed(2) : order.total}</h5>
                            </div>
                        </div>

                        <div className="payment ml-30">
                            <h4 className="mb-30">Payment</h4>
                            <div className="payment_option">
                                <div className="custome-radio">
                                    <input
                                        className="form-check-input"
                                        required=""
                                        type="radio"
                                        name="payment_option"
                                        id="exampleRadios3"
                                        defaultChecked=""
                                        disabled
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios3"
                                        data-bs-toggle="collapse"
                                        data-target="#bankTranfer"
                                        aria-controls="bankTranfer"
                                    >
                                        Direct Bank Transfer
                                    </label>
                                </div>
                                <div className="custome-radio">
                                    <input
                                        className="form-check-input"
                                        required=""
                                        type="radio"
                                        name="payment_option"
                                        id="exampleRadios4"
                                        checked
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios4"
                                        data-bs-toggle="collapse"
                                        data-target="#checkPayment"
                                        aria-controls="checkPayment"
                                    >
                                        Cash on delivery
                                    </label>
                                </div>
                                <div className="custome-radio">
                                    <input
                                        className="form-check-input"
                                        required=""
                                        type="radio"
                                        name="payment_option"
                                        id="exampleRadios5"
                                        defaultChecked=""
                                        disabled
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios5"
                                        data-bs-toggle="collapse"
                                        data-target="#paypal"
                                        aria-controls="paypal"
                                    >
                                        Online Getway
                                    </label>
                                </div>
                            </div>
                            <div className="payment-logo d-flex">
                                <img
                                    className="mr-15"
                                    src="/images/theme/icons/payment-paypal.svg"
                                    alt=""
                                />
                                <img
                                    className="mr-15"
                                    src="/images/theme/icons/payment-visa.svg"
                                    alt=""
                                />
                                <img
                                    className="mr-15"
                                    src="/images/theme/icons/payment-master.svg"
                                    alt=""
                                />
                                <img src="/images/theme/icons/payment-zapper.svg" alt="" />
                            </div>
                            <a href="" onClick={(e) => handleOrderSubmit(e)} className="btn btn-fill-out btn-block mt-30">
                                Place an Order
                                <i className="fi-rs-sign-out ml-15" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Checkout;