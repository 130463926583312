import HeaderBottom from "./HeaderBottom";
import HeaderMiddle from "./HeaderMiddle";

const Header = () => {
   return (
      <>
         <header className="header-area header-style-1 header-height-2">
            
            <HeaderMiddle />

            <HeaderBottom />
         </header>
      </>
   );
}

export default Header;