import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveOrders } from "../../services/OrderService";
import { stateByIds } from "../../services/StateService";
import Navigation from "./Navigation";

const Orders = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  const [singleOrder, setSingleOrder] = useState({});
  const [orderStates, setOrderStates] = useState([]);
  let date = '';

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrders();
  }, []);

  useEffect(async () => {
    if (singleOrder?.billing_address) {
      let ids = [singleOrder.billing_address.state_id, singleOrder.shipping_address.state_id].join(",");
      await stateByIds(ids).then((response) => {

        if (response?.data?.data) setOrderStates(response.data.data);
      }).catch((error) => {
        toast("Something went wrong", { type: 'error' });
      });
    }
  }, [singleOrder]);

  const getOrders = async () => {
    await retriveOrders().then((response) => {
      if (response?.data?.data) setOrders(response.data.data);
    }).catch((error) => {
      if (error.response.status == 422)
        toast(error.response.data.error, { type: 'error' })
      else if (error.response.status == 500)
        toast(error.response.data.message, { type: 'error' })
      else
        toast("Something went wrong", { type: 'error' });
    });
  }

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Orders
          </div>
        </div>
      </div>
      <div className="page-content pt-150 my-account-details pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content pl-50">
                    <div
                      className="tab-pane fade active show"
                      id="orders"
                      role="tabpanel"
                      aria-labelledby="orders-tab"
                    >
                      {
                        !showOrder ?

                          <div className="card">
                            <div className="card-header">
                              <h3 className="mb-0">Your Orders</h3>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead className="dashboard-order">
                                    <tr>
                                      <th>Order</th>
                                      <th>Date</th>
                                      <th>Status</th>
                                      <th>Total</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      orders.map((order, index) => {
                                        return (
                                          <tr key={index}  className="dashboard-order-list">
                                            <td>#order-{order.id}</td>
                                            <td>
                                              {(() => {
                                                date = new Date(order.created_at);
                                                if (date != null && date != 'Invalid Date') {
                                                  return date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear();
                                                }
                                              })()


                                              }
                                            </td>
                                            <td>{order.order_status}</td>
                                            <td>${order.total}</td>
                                            <td>
                                              <a href="" onClick={(e) => { e.preventDefault(); setSingleOrder(order); setShowOrder(true); }} className="btn-small d-block view-text">
                                                View
                                              </a>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          :
                          <>
                            <h2 className="woocommerce-order-details__title">Order details</h2>

                            <section className="woocommerce-order-details">
                              <p style={{ paddingTop: '10px' }}>
                                Order #<mark className="order-number">order-{singleOrder.id}</mark> was placed on{" "}
                                <mark className="order-date">{date = new Date(singleOrder.created_at).toLocaleString('default', { month: 'long' })
                                  + " " + new Date(singleOrder.created_at).getDate()
                                  + " " + new Date(singleOrder.created_at).getFullYear()}</mark> and currently status is{" "}
                                <mark className="order-status">{singleOrder.order_status}</mark>.
                                <button type="button" className="btn btn-sm" style={{ marginLeft: '10px' }} onClick={() => { setSingleOrder([]); setShowOrder(false) }}> Go Back</button>
                              </p>
                              <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                                <thead>
                                  <tr>
                                    <th className="woocommerce-table__product-name product-name">
                                      Product
                                    </th>
                                    <th className="woocommerce-table__product-table product-total">
                                      Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    singleOrder.line_items?.map((item, index) => {
                                      return (
                                        <tr key={index} className="woocommerce-table__line-item order_item">
                                          <td className="woocommerce-table__product-name product-name">
                                            <Link to={"/product/" + item.item_id + "/" + item.item.item_name} className="view-text">
                                              {item.item?.item_name}
                                            </Link>{" "}
                                            <strong className="product-quantity">×&nbsp;{item.quantity}</strong>{" "}
                                          </td>
                                          <td className="woocommerce-table__product-total product-total">
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">$</span>{item.subtotal}
                                              </bdi>
                                            </span>{" "}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  }

                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th scope="row">Subtotal:</th>
                                    <td>
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">$</span>{singleOrder.subtotal}
                                      </span>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                      <th scope="row">Shipping:</th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">$</span>5.00
                                        </span>
                                        &nbsp;<small className="shipped_via">via Flat rate</small>
                                      </td>
                                    </tr> */}
                                  <tr>
                                    <th scope="row">Payment method:</th>
                                    <td>Cash on delivery</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total:</th>
                                    <td>
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">$</span>{singleOrder.total}
                                      </span>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </section>
                            <section className="woocommerce-customer-details">
                              <section className="row">
                                <div className="col">
                                  <h5 className="woocommerce-column__title">Billing address</h5>
                                  <address>
                                    {singleOrder.billing_address.name}
                                    <br />
                                    {singleOrder.billing_address.company_name}
                                    <br />
                                    {singleOrder.billing_address.address}
                                    <br />
                                    {singleOrder.billing_address.city}
                                    <br />
                                    {orderStates[singleOrder.billing_address.state_id]}
                                    <br />
                                    {singleOrder.billing_address.pincode}

                                    <p className="woocommerce-customer-details--phone">
                                      {singleOrder.phone}
                                    </p>
                                    <p className="woocommerce-customer-details--email">
                                      {singleOrder.email}
                                    </p>
                                  </address>
                                </div>
                                {/* /.col-1 */}
                                <div className="col">
                                  <h5 className="woocommerce-column__title">Shipping address</h5>
                                  <address>
                                    {singleOrder.shipping_address.name}
                                    <br />
                                    {singleOrder.shipping_address.company_name}
                                    <br />
                                    {singleOrder.shipping_address.address}
                                    <br />
                                    {singleOrder.shipping_address.city}
                                    <br />
                                    {orderStates[singleOrder.shipping_address.state_id]}
                                    <br />
                                    {singleOrder.shipping_address.pincode}
                                  </address>
                                </div>
                                {/* /.col-2 */}
                              </section>
                              {/* /.col2-set */}
                            </section>
                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>


  );
}

export default Orders;